.table-list {
  border-collapse: collapse;
  width: 100%;
  .gray-row {
    background-color: #f4f4f4;
  }
  .white-row {
    background-color: #ffffff;
  }
  .very-poor-color {
    color: #f2464b;
  }
  .poor-color {
    color: #ff9f43;
  }
  .average-color {
    color: #ff822e;
  }
  .good-color {
    color: #83d17a;
  }
  .very-good-color {
    color: #00c851;
  }
  .excellent-color {
    color: #018d3a;
  }
  td,
  th {
    text-align: left !important;
    padding: 8px !important;
  }
}
tr:nth-child(even) {
  background-color: #f4f4f4;
}
td {
  tr:nth-child(even) {
    background-color: #f4f4f4;
  }
}
