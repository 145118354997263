.toggle-switch,
.toggle-switch-disabled {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  border-radius: 1.2em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #c0c0c0;
  transition: background-color 0.2s ease;
  // border: 1px solid $theme-peacock;
  border: 1px solid #e7ebfb;
  padding: 5px;
}

.toggle-switch .switch::before {
  position: absolute;
  top: 2px;
  left: 2.5px;
  content: "";
  width: 15px;
  height: 15px;
  background-color: #626262;
  // background-color: $theme-peacock;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(13px);
  // background-color: $White-Color;
  background-color: $theme-peacock;
}

.toggle-switch input[type="checkbox"]:checked + .switch {
  // background-color: $theme-peacock;
  background-color: #e7ebfb;
}

.toggle-switch-disabled input[type="checkbox"] {
  display: none;
}

.toggle-switch-disabled .switch {
  position: absolute;
  cursor: pointer;
  border-radius: 1.2em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  border: 1px solid $theme-border;
  padding: 5px;
}

.toggle-switch-disabled .switch::before {
  position: absolute;
  top: 2px;
  left: 2.5px;
  content: "";
  width: 15px;
  height: 15px;
  background-color: $theme-border;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch-disabled input[type="checkbox"]:checked + .switch::before {
  transform: translateX(13px);
  background-color: $White-Color;
}

.toggle-switch-disabled input[type="checkbox"]:checked + .switch {
  background-color: $theme-border;
}

//permission-toggle-switch-css
.permission-toggle-switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.25em;
  .switch-on {
    position: absolute;
    height: 1.2em;
    width: auto;
    top: 1px;
    left: 1px;
    z-index: 1;
  }
  .switch-off {
    position: absolute;
    height: 1.2em;
    width: auto;
    top: 1px;
    right: 1px;
    z-index: 1;
  }
}

.permission-toggle-switch input[type="checkbox"] {
  display: none;
}

.permission-toggle-switch .switch {
  border-radius: 2px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  background-color: #d9d9d9;
}

.permission-toggle-switch .switch::before {
  position: absolute;
  content: "";
  background-image: url("../../images/permission-switch-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  top: 2px;
  left: 2px;
  border-radius: 2px;
  background-color: #fff;
  transition: transform 0.3s ease;
}

.permission-toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(1.2em);
  background-color: white;
}

.permission-toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #3aa93d;
}
.permission-toggle-switch input[type="checkbox"]:disabled + .switch {
  background-color: #d9d9d9;
}
//permission-toggle-switch-css
