.filter-btn {
  height: 37px;
  width: 37px;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: border-box;
  border: 1.5px solid $theme-blue;
}

.filter-img {
  height: 60%;
  width: auto;
}

.filter-clear-btn {
  text-align: center;
  font-size: 10px;
  margin: 5px 0px;
  color: #1f407e;
  background-color: transparent;
  border: none;
  font-weight: bold;
}
.filter-clear-btn.disabled {
  opacity: 0.6;
  font-weight: 500;
}
// .onboardedclients-filter-btn {
//   top: 110%;
//   right: 110%;
//   background-color: $White-Color;
//   width: 10em;
//   height: 10em;
//   padding: 20% 40%;
//   z-index: 100000;
//   display: flex;
//   flex-direction: column;
//   overflow-y: auto;
//   .date-container {
//     label {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//       span {
//         color: $theme-border;
//         margin: 5% 0;
//       }
//       input {
//         border-style: none;
//         color: $theme-subuser;
//         outline: none;
//         border-bottom: 1px solid $theme-border;
//         &::-webkit-calendar-picker-indicator {
//           background-image: url("../../images/calender.svg");
//         }
//       }
//     }
//   }
//   .title {
//     margin: 5% 0;
//     color: $theme-border;
//   }

//   label {
//     margin-bottom: 2%;
//     color: $theme-subuser;
//     input {
//       margin-right: 5%;
//     }
//   }
// }
.filter-btn-popup {
  top: 110%;
  right: 110%;
  background-color: $White-Color;
  width: 10em;
  max-height: 21em;
  max-width: 16em;
  width: auto;
  padding: 20% 40%;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .select__control {
    min-height: unset;
  }
  .select__control .select__value-container {
    padding: 1em !important;
  }
  .select__single-value {
    color: hsl(0, 0%, 50%);
  }
  .date-container {
    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        color: $theme-border;
        margin: 5% 0;
      }
      .filter-date-container {
        width: 100px;
        position: relative;
        padding: 5px;
        display: flex;
        align-items: center;
        border-radius: 3px;
        .filter-input {
          font-size: 12px;
          width: 75px;
        }
        .filter-date-placeholder {
          width: 75px;
          position: absolute;
          left: 5px;
          top: 0;
          color: #8780ab;
          font-size: 12px;
          background-color: #ffffff;
          padding: 5px 0;
        }
        .filter-date-logo {
          width: auto;
          height: 12px;
        }
      }
      input {
        border-style: none;
        color: $theme-subuser;
        outline: none;
      }
    }
  }

  .title {
    margin: 5% 0;
    color: $theme-border;
  }

  label {
    margin-bottom: 2%;
    color: $theme-subuser;
    input {
      margin-right: 5%;
    }
  }
}

.question-filter-btn-popup {
  top: 110%;
  right: 110%;
  background-color: $White-Color;
  width: 10em;
  height: 240px;
  padding: 20% 40%;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .qfb-select {
    background-color: $theme-light-blue;
    border-radius: 4px;
    .qfb-option {
      padding: 4px 0;
    }
  }
}
.event-track-filter-popup {
  top: 110%;
  right: 110%;
  background-color: $White-Color;
  width: 250px;
  height: auto;
  padding: 40%;
  z-index: 100000;
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      color: $theme-border;
      margin: 5% 0;
    }
    input {
      border-style: none;
      color: $theme-subuser;
      outline: none;
      border-bottom: 1px solid $theme-border;
      &::-webkit-calendar-picker-indicator {
        background-image: url("../../images/calender.svg");
      }
    }
  }
}

// .range-slider {
//   position: relative;
// }

// .rs-label {
//   position: absolute;
//   top: 0;
//   background-image: url("../../images/droplet.svg");
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 28px 28px;
//   height: 28px;
//   width: 28px;
//   color: $White-Color;
// }
// .box-minmax {
//   width: 100%;
//   margin: 1% auto;
//   display: flex;
//   justify-content: space-between;
//   color: $theme-black;
// }
// .rs-range {
//   display: flex;
//   align-items: center;
//   appearance: none;
//   background: none;
//   cursor: pointer;
//   display: flex;
//   overflow: hidden;
//   height: 20px;
//   width: 90%;
//   margin: 0 auto;

//   &:focus {
//     box-shadow: none;
//     outline: none;
//   }

//   &::-webkit-slider-runnable-track {
//     background: $theme-placeholder;
//     height: 1.5px;
//   }

//   &::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     appearance: none;
//     background-color: $theme-blue;
//     height: 10px;
//     width: 10px;
//     margin-top: -5px;
//     border-radius: 50%;
//   }

//   &::-moz-range-track {
//     background: $theme-placeholder;
//     height: 1.5px;
//   }

//   &::-moz-range-thumb {
//     background: $theme-blue;
//     border-radius: 50%;
//     width: 10px;
//     height: 10px;
//   }
//   &::-webkit-progress-bar {
//     height: 2px;
//     background-color: $theme-blue;
//   }
//   &::-moz-progress-bar {
//     height: 2px;
//     background-color: $theme-blue;
//   }
// }
.range-box {
  width: 200px;
  margin: 0 auto;
}
.range-slider {
  position: relative;
  margin-top: 30px;
  width: 200px;
}

.rs-label {
  position: absolute;
  bottom: 20px;
  background-image: url("../../images/droplet.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
  color: $White-Color;
}
.box-minmax {
  width: 100%;
  margin: 1% auto;
  display: flex;
  justify-content: space-between;
  color: $theme-black;
}
.rs-range {
  width: 180px;
  display: flex;
  align-items: center;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  height: 20px;
  margin: 0 auto;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: $theme-placeholder;
    height: 1.5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: $theme-blue;
    height: 10px;
    width: 10px;
    margin-top: -5px;
    border-radius: 50%;
  }

  &::-moz-range-track {
    background: $theme-placeholder;
    height: 1.5px;
  }

  &::-moz-range-thumb {
    background: $theme-blue;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
  &::-webkit-progress-bar {
    height: 2px;
    background-color: $theme-blue;
  }
  &::-moz-progress-bar {
    height: 2px;
    background-color: $theme-blue;
  }
}
