.subuser-table {
  .w-5 {
    width: 5%;
  }
  .w-30 {
    width: 30%;
  }
  .w-15 {
    width: 15%;
  }
  .w-20 {
    width: 20%;
  }
  .subuser-table-header,
  .subuser-rows {
    display: flex;
    align-items: center;
    background-color: $theme-light-blue;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

    .subuser-header-content {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $theme-subuser;
      img {
        height: 0.6em;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .subuser-table-header {
    margin: 0px 0px 10px 0px;
    padding: 20px 0;
    @media screen and (max-width: 992px) {
      min-width: 980px;
    }
  }

  .subuser-row-container {
    @media screen and (max-width: 992px) {
      min-width: 1000px;
    }
  }

  .subuser-rows {
    padding: 10px 0;
    margin-bottom: 10px;
    .sub-row-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .resend-mail-btn {
      border-style: none;
      background-color: $theme-light-blue;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5em;
      height: 1.5em;
      img {
        height: 1em;
      }
    }
  }

  .active {
    color: $theme-light-green;
  }

  .inactive {
    color: $theme-error;
  }
}

.add-subuser-container {
  position: relative;
  overflow-y: auto;
  .asc-title {
    margin-left: 20px;
    color: $theme-blue;
  }
  .asc-input-container {
    width: 370px;
    margin: 10px 0;
  }

  .add-sub-btn {
    background-color: $theme-blue;
    color: $White-Color;
    padding: 0.8em 1.2em;
    margin-top: 10px;
  }

  .add-sub-bg {
    position: absolute;
    right: 20px;
    top: 20%;
    height: 15em;
  }
}
