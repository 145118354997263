.mainHeader {
  .collapse-btn {
    border-style: none;
    background-color: transparent;
    height: 1em;
    img {
      height: 100%;
    }
  }

  .head-notification {
    height: 1.2em;
    margin: 0 25px;
  }

  .profile-name {
    margin-right: 25px;
  }

  .profile-pic {
    height: 1.8em;
    width: 1.8em;
    background-color: $theme-blue;
    color: $White-Color;
    border-radius: 50%;
  }
  .notification,
  .profile-pic,
  .profile-name {
    cursor: pointer;
  }
  .profile-menu {
    top: 30px;
    right: 10%;
    background-color: #fff;
    width: 14em;
    z-index: 999;
    box-shadow: 0 3px 5px 0px #8d8d8d80;
    .profile-menu-content {
      margin: 10px;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background-color: $theme-light-blue;
      }
      .logout {
        height: 1em;
        width: 1em;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.product-dropdown {
  margin-left: 20px;
  font-size: 14px;
  color: $theme-blue;
  position: relative;
  .select-product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 35px;
    width: 150px;
    background: #f6f7fb;
    border-radius: 3px;
    cursor: pointer;
  }
  .product-list {
    position: absolute;
    width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 2px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 1;
    .pl-product {
      width: 100%;
      padding: 5px;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        background-color: #f6f6f6;
      }
    }
    .pl-divider {
      width: 100%;
      height: 1px;
      background-color: #f6f6f6;
    }
  }
}

.product-show-btn {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  height: 35px;
  align-items: center;
  padding: 0 15px;
  background: #133f7d;
  border-radius: 5px;
  border: none;
  color: $White-Color;
  font-size: 14px;
}
.count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  font-size: 8px;
  border-radius: 50%;
  background: $theme-red;
  position: absolute;
  top: -20%;
  right: -5%;
}

.notification-dropdown {
  right: 10px;
  width: 300px;
  height: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: $White-Color;
  overflow: hidden;
  z-index: 1111;
  .title {
    height: 10%;
    color: $theme-subuser;
    padding: 5px 10px;
  }

  .card-container {
    height: 80%;
    overflow-y: auto;
    background: #ccd2e34d;
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $theme-blue;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $theme-blue;
    }
  }

  .view-all {
    height: 35px;
    color: $White-Color;
    background-color: $theme-blue;
  }

  .notification-card {
    padding: 10px 5px;
    background-color: $White-Color;
    margin: 6px 6px 0px 6px;
    border-left: 5px solid $theme-blue;
    .notify-img {
      width: 25px;
      height: 25px;
      margin-top: 2px;
      margin-right: 10px;
    }
    .message-container {
      height: 100%;
      color: #292929;
      .content-name {
        width: calc(100% - 20px);
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        color: $theme-subuser;
      }
      .time {
        text-align: end;
      }
    }
    .notification-close {
      height: 10px;
      width: 10px;
      top: 5px;
      right: 5px;
      padding: 0;
      border-radius: 0;
    }
  }
}
