//roles//
.search-height {
  height: calc(100% - 389px) !important;
  overflow: auto;
}
.admin-role-container {
  display: flex;
  flex-direction: column;
  .w-10 {
    width: 10%;
  }
  .w-15 {
    width: 15%;
  }
  .w-30 {
    width: 30%;
  }
  .active {
    color: $theme-light-green;
  }
  .inactive {
    color: $theme-error;
  }
  .role-table-header {
    background-color: $theme-light-blue;
    color: $theme-subuser;
    margin-bottom: 1em;
    display: flex;
    @media screen and (max-width: 992px) {
      min-width: 980px;
    }
    .rth-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5em 0;
      img {
        width: 1em;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  .roles-list-container {
    @media screen and (max-width: 992px) {
      min-width: 1000px;
    }
    .permission {
      text-decoration: underline;
      color: $theme-blue;
      text-decoration-color: $theme-blue;
    }
    .role-table-row {
      display: flex;
      background-color: $White-Color;
      color: $theme-subuser;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      margin-bottom: 1em;
      .rtc-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 1em 0;
      }
    }
  }
}
//roles//

//Subuser//
.admin-addsubuser-border {
  height: 1px;
  background-color: $theme-subuser;
  margin-top: 0.4em;
}

.admin-add-subuser-form {
  .input {
    width: 30%;
    margin: 10px 0;
  }
  img {
    width: 50%;
    right: 0;
    top: 10%;
  }

  .input {
    .common-btn {
      padding: 1em 1.5em;
    }
  }
  .jd-form-container {
    width: 60%;
  }
  .add-on-services {
    height: 180px;
    width: 40%;
    .service-container {
      overflow-y: auto;
      .service {
        .service-remove {
          height: 2px;
          width: 2px;
        }
      }
    }
  }
}
//Subuser//
