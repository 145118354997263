.sidebar {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.sidebarLogo {
  width: 100%;
  height: 50px;
  padding: 5px;
  img {
    height: 100%;
    display: block;
    margin: 0 auto;
  }
}

.sidebarContentContainer {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  color: $White-Color;
  .admin-sidebar,
  .product-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: $theme-fog;
    border-radius: 10px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .sidebar-content-main-title {
    margin: 10px;
  }

  .sidebarContent {
    text-decoration: none;
    overflow: hidden;
    color: $White-Color;
    .mainContent {
      margin-bottom: 0px;
      text-decoration: none;
      padding: 0.5em;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background-color: $theme-fog;
      }
      .contentImg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      .contentArrow {
        height: 10px;
        width: 10px;
      }
      .dot {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: $White-Color;
        margin: 0 10px;
      }
    }
    .active {
      background-color: $theme-fog;
    }
  }
}

.w-200 {
  width: 200px;
}
.w-60 {
  width: 60px;
}
.sidebarContentContainer .sidebarContent .mainContent.subMenu{
  margin-left: 15px;
}
.sidebarContentContainer .sidebarContent .mainContent .contentImg.subMenuImg{
  width: 20px;
  height: 20px;
}
.me-13{
  margin-right: 13px;
}
