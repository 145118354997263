.announcement-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: calc(100% - 200px);
  @media screen and (max-width: 992px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .w-20 {
    width: 20%;
  }
  .w-40 {
    width: 40%;
  }

  .ac-input-container {
    width: 340px;
    height: 260px;
  }

  .ac-user-container {
    width: 340px;
    height: 100%;

    .header-search-container {
      .ac-header {
        margin-right: 3%;
        display: flex;
        align-items: center;
        background-color: $theme-light-blue;
        padding: 1em 0;
        color: $theme-subuser;
      }
      .ac-searchbar {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 3%;
        display: flex;
        align-items: center;
        background-color: $White-Color;
        .search-box {
          width: 86%;
          outline: none;
          height: 3em;
          border-style: none;
          margin-left: 2%;
        }
        .search-icon {
          width: 6%;
          margin-left: 8%;
        }
      }
    }
    .user-list-container {
      height: 145px;
      overflow-y: auto;
      .list {
        display: flex;
        align-items: center;
        padding: 10px 10px 10px 0px;
        width: calc(100% - 6px);
        margin-bottom: 5px;
        background-color: $White-Color;
      }
    }
  }
}
.ac-btn-container {
  width: 80%;
  margin-top: 50px;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
