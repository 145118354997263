.interview-search {
  .interview-search-header {
    .interview-search-para {
      color: #1c4784;
    }
  }
}
.interview-search-wrapper {
  margin: 20px;
}
.space-btw {
  justify-content: space-between;
}
.d-flex {
  display: flex;
}

.interview-search-list-container {
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
}
.interview-search-list-tags {
  border-radius: 5px 5px 0px 0px;
  color: white;
  padding: 0 25px;
}
.interview-search-list-wrapper {
  padding-bottom: 20px;
  @media screen and (min-width: 992px) {
    width: 49%;
  }
}
.interview-search-list-id {
  background-color: #133f7d;
}
.interview-search-interview-status {
  background-color: #00c851;
  margin-left: 12px;
}
.interview-search-bottom-div {
  display: flex;
  justify-content: space-between;
}
.int-search-list-outer-wrap {
  max-height: 450px;
  overflow-y: scroll;
}
.int-search-bottom-div {
  background-color: white;
  padding: 10px 20px;
  margin-right: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  color: #8b9abe;
  height: 90%;
  @media screen and (min-width: 992px) {
    padding: 10px 10px;
    margin-right: 5px;
  }
}
.int-search-panelist-info {
  display: flex;
}
.interview-search-name-div {
  display: flex;
}
.interview-search-title-name-div {
  display: flex;
  @media screen and (min-width: 992px) {
    margin-bottom: 10px;
  }
}
.interview-search-panelist-wrap-div {
  display: block;
  @media screen and (min-width: 992px) {
    margin-bottom: 10px;
    display: block;
  }
}
.interview-search-name {
  color: black;
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}
.interview-search-name-split {
  color: black;
  display: block;
  margin: 0;
  text-decoration: underline;
}
.interview-search-subname-split {
  color: black;
  display: block;
  margin: 0;
}

.interview-search-resume-img {
  width: 100%;
  height: auto;
}
.interview-search-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.interview-search-jd-div {
  margin-left: 25px;
  @media screen and (min-width: 992px) {
    margin-left: 10px;
  }
}
.interview-search-panelist-div {
  margin-right: 10px;
}
.interview-search-panelist-span {
  align-items: center;
  display: flex;
  justify-content: center;
}
.interview-search-clock-div {
  margin-right: 7px;
  @media screen and (min-width: 992px) {
    margin-right: 10px;
    margin-left: 0;
  }
}
.interview-search-feedback-container {
  display: flex;
  justify-content: space-between;
}

.interview-search-feedback-btn {
  background-color: #133f7d;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 6px 7px;
  text-decoration: none;
  text-decoration-color: none;
  &:hover {
    color: white;
  }
}
.feedback_link {
  text-decoration: none;
}
.interview-search-right {
  display: flex;
  flex-direction: column;
}
.int-search-gotoimg {
  margin-left: 10px;
}
.int-search-icon-wrapper {
  background: #e6e9f8;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.int-search-icon {
  background-color: #e6e9f8;
  width: 1rem;
  height: 1em;
}
.bg-white {
  background-color: white;
}
.int-list-header-wrapper {
  background-color: #e6e9f8;
  color: #626262;
  margin-bottom: 1em;
  padding: 17px 20px;
  display: flex;
}
.int-search-rth-content {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1.5em 0;
}

.int-search-arrow-img {
  margin-left: 5px;
  width: 1em;
  cursor: pointer;
}
.int-list-wrapper {
  background-color: white;
  margin-bottom: 1em;
  padding: 10px;
  display: flex;
}
.color-blue {
  color: #133f7d;
}
.align-center {
  text-align: center;
}
.int-search-list-icon-wrapper {
  background: #e6e9f8;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.int-search-btn {
  img {
    width: 1em;
    height: 1em;
  }
}
.int-search-switch-view {
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  margin-right: 22px;
}
.int-search-switch-view-img {
  height: 20px;
  width: auto;
}
.int-search-invite-btn {
  background-color: #133f7d;
  border: none;
  border-radius: 3px;
  color: white;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 10px;
  }
}
.int-search-invite-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.int-search-feedback {
  display: flex;
  align-items: center;
  justify-content: center;
}
.int-list-outer {
  height: 225px;
  overflow-y: scroll;
}
.int-list-outer.incExit_int_list_outer {
  height: 84%;
}
.int-list-outer.incExit_int_list_outer .int-list-wrapper {
  padding: 8px 10px;
}
.int-list-header-wrapper.incExit-list-header-wrapper {
  padding: 10px 10px;
}
