.sw-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $White-Color;
  border-radius: 0.5em;
  gap: 10px;
  padding: 1em;
  width: 120px;
  margin: 10px;
}
.sw-card-V2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $White-Color;
  border-radius: 0.5em;
  gap: 10px;
  padding: 1em;
  width: 33%;
  margin: 5px;
}