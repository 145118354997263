.setting-page-container{
    position: absolute;
    background: #fff;
    width: 80%;
    left: 10%;
    top: 10%;
    right: 10%;
    padding: 5%;
    z-index: 10000;
    .btn-close{
        top: 5%;
        right: 5%;
        position: absolute;
    }
}