.back-btn-container {
  cursor: pointer;
  img {
    height: 0.6em;
  }
  .back {
    color: $theme-blue;
    font-weight: bold;
    margin-left: 5px;
  }
}
