.addon-popup-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.addon-popup-content-div {
  background-color: #f6f7fb;
  padding: 25px 35px;
  border-radius: 10px;
  width: 760px;
}
.addon-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addon-popup-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.addon-popup-input {
  width: 32%;
  margin-bottom: 10px;
}
.padding-none {
  padding: 0 !important;
}
.wi-100 {
  width: 100% !important;
}
.addon-popup-cancel-btn {
  background: #f6f7fb;
  color: #133f7d;
  border: 1px solid #133f7d;
}
.addon-popup-credit-btn {
  background: #133f7d;
  color: #ffffff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addon-popup-credit-add {
  font-weight: 1000 !important;
  margin-left: 10px;
}
