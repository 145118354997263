.add-role-btn {
  background-color: $theme-blue;
  color: $White-Color;
  padding: 10px 20px;
  border-radius: 5px;
}

.roles-table {
  height: 84%;
  // overflow: auto; {scrolls the whole container, check all before uncommenting}
  .w-30 {
    width: 30%;
  }
  .w-15 {
    width: 15%;
  }
  .w-10 {
    width: 10%;
  }
  .active {
    color: $theme-light-green;
  }
  .inactive {
    color: $theme-error;
  }
  .role-table-header,
  .role-table-row {
    display: flex;
    align-items: center;
    height: 4em;
    margin: 10px 0;
    span {
      text-align: center;
    }
  }
  .role-table-header {
    background-color: $theme-light-blue;
    color: $theme-subuser;
    @media screen and (max-width: 992px) {
      min-width: 980px;
    }
    .rth-content {
      img {
        width: 0.9em;
        height: auto;
        margin-left: 5px;
      }
    }
  }
  .role-table-container {
    height: 78%;
    overflow-y: auto;
    @media screen and (max-width: 992px) {
      min-width: 1000px;
    }
  }
  .role-table-row {
    background-color: $White-Color;
    .permission {
      text-decoration: underline;
      color: $theme-blue;
      text-decoration-color: $theme-blue;
    }
  }
}

.add-role-container {
  .arc-title-container {
    display: flex;
    align-items: center;
    height: 30px;
    .arc-title {
      color: $theme-blue;
      margin-left: 25px;
    }
  }
  .add-role-part-container {
    height: calc(100% - 30px);
  }
  .add-role-part1 {
    // width: 90%;
    height: 140px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    .add-role-subpart1 {
      width: 290px;
      height: auto;
    }
  }
  .add-role-part2 {
    width: 100%;
    padding-left: 10px;
    border-left: 1.4px solid $theme-border;
    .permissions-container {
      margin-top: 13px;
      .permissions-tab-container {
        display: flex;
        .permission-tab {
          font-size: 14px;
          padding: 9px 16px;
          border: 1px solid #f0f0f0;
          margin-right: 4px;
          min-width: 127px;
          height: 40px;
          text-align: center;
          cursor: pointer;
        }
        .permission-tab-active {
          background-color: #133f7d;
          color: #ffffff;
        }
        .permission-tab-inactive {
          background-color: #ffffff;
          color: #000000;
        }
      }
      .permissions-row-container {
        border-top: 1.4px solid #f0f0f0;
        .permissions-header {
          display: flex;
          justify-content: end;
          padding: 15px 28px;
          .permissions-header-title {
            text-align: center;
            font-size: 11px;
            width: 100px;
          }
        }
        .permission-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #fff;
          padding: 8px 28px;
          min-height: 45px;
          margin-bottom: 10px;
          .permission-row-part-1 {
            .permission-row-title {
              font-size: 14px;
            }
            .permission-row-subtitle {
              font-size: 10px;
            }
          }
          .permission-row-part-2 {
            .permission-box-type-1 {
              display: flex;
              .permission-checkbox-container {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                .permission-checkbox {
                  width: 13px;
                  height: 13px;
                  accent-color: #133f7d;
                  cursor: pointer;
                }
              }
            }
            .permission-box-type-2 {
              display: flex;
              align-items: center;
              .permission-drop-arrow {
                width: 20px;
                height: auto;
                margin-left: 25px;
                cursor: pointer;
              }
              .permission-arrow-down {
                rotate: 90deg;
              }
            }
          }
        }
      }
    }
  }
  .action-des-container {
    list-style: none;
    margin: 10px 10px 0 0;
    padding: 0;
  }
  .arc-input-container {
    margin-right: 10px;
  }

  .arc-permission {
    color: $theme-blue;
    font-size: 15px;
    text-align: center;
  }
}

.bg-light-blue {
  background-color: $theme-light-blue;
}

.arc-divider {
  height: 1px;
  background-color: $theme-light-blue;
  width: 90%;
  margin-top: 10px;
}

.add-role-container2 {
  .arc-title-container2 {
    display: flex;
    align-items: center;
    padding: 10px;
    .arc-title2 {
      font-size: 20px;
      color: $theme-blue;
      margin-left: 10px;
    }
  }
  .add-role-part-container2 {
    .add-role-input-container {
      display: flex;
      .add-role-input {
        width: 40%;
      }
      .add-role-info {
        margin-bottom: 0px;
      }
    }
    .permission-drop-down {
      width: 85px;
      border: 1.5px solid $theme-light-blue;
      border-radius: 5px;
      height: 30px;
    }
  }
}
.add-role-btn-new {
  background-color: #133f7d;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  width: 100px;
}

.empl-page .excel-btn {
  width: 120px;
  padding: 5px;
  font-size: 10px;
}
.empl-page .excel-btn span {
  font-size: 10px;
}
