.drag-drop-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-right: 10px;
  .dnd-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 45px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: $White-Color;

    .dragger {
      width: 40px;
      height: 32px;
      border-right: 1.5px solid $theme-subuser;
    }
    .input-item {
      position: relative;
      height: 32px;
      width: calc(100% - 82px);
      padding: 10px;
      color: $theme-subuser;
      border: none;
      outline: none;
      cursor: move;
      background-color: transparent;
    }
    .delButton {
      height: 32px;
      width: 32px;
      background-color: $theme-light-blue;
      cursor: pointer;
    }
  }
  .addButton {
    display: flex;
    align-items: center;
    margin: auto;
    line-height: 26px;
    border: none;
    color: black;
    border-radius: 50%;
    font-size: 24px;
  }
}
.over {
  transform: scale(1.01);
  border: 1px dashed #eee;
}
