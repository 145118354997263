.cpp-wrapper {
  background-color: $Back-ground;
  position: relative;
  width: 100%;
  padding: 20px;
  @media screen and (max-width: 992px) {
    padding: 1em 1em;
  }
  @media screen and (min-width: 1900px) {
    padding: 3em 3.5em;
  }
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $theme-blue;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $theme-blue;
  }

  .cpp-title {
    color: $theme-blue;
    .cpp-title-heading {
      display: inline-block;
      border-bottom: 3px solid #133f7d;
      padding: 11px 6px;
      margin: 0;
    }
    .interview-tabs-box {
      .interview-tab {
        padding: 8px 15px;
        background-color: #e9e9e9;
        border-left: 1px solid #f0f0f0;
        border-top: 1px solid #f1f1f1;
        border-right: 1px solid #f5f5f5;
        margin: 0 0.5px;
        border-top-left-radius: 0.4em;
        border-top-right-radius: 0.4em;
        cursor: pointer;
        color: $theme-subuser;
      }
      .selected {
        background-color: $theme-blue;
        color: $White-Color;
      }
    }
  }

  .input-wrapper {
    width: 46%;

    .input[type="file"] {
      display: none;
    }

    ::-webkit-file-upload-button {
      visibility: hidden;
    }

    input::placeholder {
      color: $theme-placeholder;
    }

    .input {
      height: 4em;
      border-style: none;
      background-color: $White-Color;
    }
  }

  .cpp-bg {
    @media screen and (max-width: 1000px) {
      display: none;
    }
    position: absolute;
    width: 38%;
    right: 2.5%;
    top: 30%;
  }

  .questions-wrapper {
    @media screen and (max-width: 900px) {
      width: 100%;
      position: relative;
      z-index: initial;
      top: 0;
      right: 0;
    }
    z-index: 3;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 39%;
    height: calc(100% - 60px);
    padding: 30px 30px 10px 30px;
    background-color: #f0f2f9;

    .screen-qs-container {
      height: calc(100% - 132px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $theme-blue;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $theme-blue;
      }
    }

    .btn-close {
      width: 5px;
      height: 5px;
      top: 2.5%;
      right: 1.5%;
    }

    .custom-qs {
      padding: 1.2em;
      outline: none;
      width: 95%;
    }

    .custom-qs-box {
      background-color: $White-Color;
      border-radius: 0.3em;
      overflow: hidden;
    }

    .screen-ques-remove {
      height: 2px;
      width: 2px;
    }
  }
  .spacer-div {
    margin-right: 18px;
  }
  .cpp-btn {
    padding: 0.6em 2em;
    border-radius: 0.5em;
  }
  a {
    text-decoration: none;
  }

  .confirm-interview-popup {
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    .ci-popup {
      // background-color: $theme-bg;
      padding: 40px;
      border-radius: 0.5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      .ci-btn-container {
        margin-top: 25px;
        .ci-btn {
          width: 6em;
          height: 3em;
          border-style: none;
          margin: 0 10px;
          color: $White-Color;
        }
        .yes {
          background-color: $theme-light-green;
        }
        .no {
          background-color: $theme-subuser;
        }
      }
    }
    .bidding-popup {
      // background-color: $theme-bg;
      padding: 20px 10px;
      border-radius: 0.5em;
      width: 370px;
      .time-left {
        .timer {
          height: 1.2em;
        }
        .left-bid {
          color: $theme-subuser;
        }
      }
      .current-bid {
        background-color: $White-Color;
        margin-top: 30px;
        text-align: center;
        padding: 10px;
        .bid-by {
          color: $theme-subuser;
        }
      }
      .bid-input-box {
        margin-top: 25px;
        background-color: $White-Color;
        padding: 10px 20px;
        .bid-label {
          color: $theme-subuser;
        }
        .bid-input {
          outline: none;
          padding: 0 10px;
          border: none;
          background-color: $White-Color;
        }
      }
      .bid-input-placed {
        color: $theme-light-green;
        padding: 10px 20px;
        background-color: $White-Color;
        margin-top: 10px;
      }
      .bid-now-btn {
        padding: 0.5em 0;
        border-radius: 0.3em;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  .panels-table {
    // min-height: 300px;
    // max-height: 300px;
    height: 65%;
    display: flex;
    flex-direction: column;
    .w-5 {
      width: 5%;
    }
    .w-10 {
      width: 10%;
    }
    .w-15 {
      width: 15%;
    }
    .w-20 {
      width: 20%;
    }
    .w-30 {
      width: 30%;
    }
    .panels-header {
      background-color: $theme-light-blue;
      color: $theme-subuser;
      display: flex;
      margin-right: 10px;
      .header-content {
        padding: 15px;
        display: flex;
        align-items: center;
        .double-arrow {
          height: 0.8em;
          margin-left: 10px;
        }
      }
    }
    .panels-content {
      height: 82%;
      overflow-y: auto;
      padding-right: 10px;
      .panel {
        display: flex;
        background-color: $White-Color;
        .active {
          color: $theme-light-green;
        }
        .inactive {
          color: $theme-red;
        }
        span {
          padding: 10px;
        }
      }
    }
  }
  .addPanelist-sideForm {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 50%;
    height: calc(100% - 60px);
    background-color: #ebedf9;
    box-shadow: -2px 0px 12px -4px rgba(105, 105, 105, 1);
    padding: 20px 10px 20px 20px;
    .addPanelist-sideForm-scroll {
      height: 300px;
      overflow-y: auto;
      padding-right: 10px;
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: space-between !important;
    }
    .addPanelistInput {
      width: 48%;
    }
    .btn-close {
      width: 5px;
      height: 5px;
    }
  }
}
.notification-container {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: $theme-light-blue;
    color: $theme-subuser;
    margin-right: 10px;

    .clear-all {
      color: $theme-blue;
      cursor: pointer;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    .content {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: $White-Color;
      margin-top: 10px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      border-left: 6px solid $theme-blue;
      .notification-delete {
        width: 2.5px !important;
        height: 2.5px !important;
      }
      .date-time {
        color: #626262;
      }
      .user-info {
        display: flex;
        width: 90%;
        .user-name {
          margin-right: 10px;
          .not-img {
            height: 30px;
            width: 30px;
            margin-top: 2px;
          }
        }
        .notification {
          width: 95%;
          display: flex;
          flex-direction: column;
          .not-title {
            color: $theme-blue;
            line-height: 1;
          }
          .not-message {
            color: #626262;
          }
        }
      }
    }
  }
}
