.login-main {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.login-left {
  display: flex;
  flex-basis: 30%;
  width: 100%;
  min-width: 30%;
  transition: 0.3s all linear;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.logo-img {
  margin-bottom: 15px;
  height: 5em;
}

.login-right {
  display: flex;
  padding: 2em 3em;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: $Back-ground;

  @media screen and (max-width: 769px) {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  @media screen and (min-width: 1440px) {
    padding-left: 2em;
    padding-right: 2em;
  }

  @media screen and (min-width: 1536px) {
    padding-left: 4em;
    padding-right: 4em;
  }

  @media screen and (min-width: 1600px) {
    padding-left: 5em;
    padding-right: 5em;
  }

  @media screen and (min-width: 1700px) {
    padding-left: 7em;
    padding-right: 7em;
  }
  .highlight {
    text-decoration: none;
    color: $theme-blue;
    font-weight: 700;
  }
}

.loginFormContainer {
  padding: 0 3em;
  width: 100%;

  @media screen and (max-width: 922px) {
    padding: 0 1em;
  }

  @media screen and (max-width: 769px) {
    padding: 0 1em;
  }
}

.loginInputContainer {
  max-width: 370px;
  width: 100%;
}

.login-signup-btn {
  width: 100%;
}
.google-div {
  height: 38px;
  width: 100%;
}
.google-div iframe {
  margin: 0px auto !important;
  left: -10px !important;
}
.google-div div {
  max-height: 34px !important;
}
.loginInputForm {
  display: flex;
  justify-content: space-between;
}
.forgot-password {
  cursor: pointer;
}
.nsm7Bb-HzV7m-LgbsSe {
  border: none !important;
}
.justify-content-between {
  justify-content: space-between;
}
.remember-justify {
  justify-content: space-between;
}
.form-check-input[type="checkbox"] {
  border-radius: 4px !important;
  width: 18px;
  height: 18px;
}
.loginFormContainer {
  .btn-div-login.col-12 {
    margin-top: 3px;
    max-width: 370px;
    padding: 0px !important;
    width: 100%;
  }
}
