.il-title-container {
  background-color: $White-Color;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  padding: 0.5em;

  .filter-btn {
    margin-left: 10px;
  }
}

.il-container {
  margin-top: 30px;
  height: 78%;

  .w-2 {
    width: 2.5%;
  }
  .w-5 {
    width: 5%;
  }
  .w-10 {
    width: 10%;
  }
  .w-15 {
    width: 15%;
  }
  .w-20 {
    width: 20%;
  }
  .w-25 {
    width: 25%;
  }
  .w-30 {
    width: 30%;
  }
  .header {
    background-color: $theme-light-blue;
    color: $theme-subuser;
    margin-bottom: 1%;
    margin-right: 1%;
    border: 1px solid red;

    .header-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5em 0;
      border: 1px solid blue;

      img {
        width: 1em;
        margin-left: 5px;
      }
    }
  }
  .row-container {
    height: 80%;
    overflow-y: auto;
    padding-right: 1%;

    .interview-row {
      background-color: $White-Color;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      margin-bottom: 1em;
      .row-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5em 0;
        border: 1px solid blue;

        .light-blue-btn {
          width: 2em;
          height: 2em;
          border-radius: 0.3em;
        }

        .interview-link {
          padding: 0.5em 1em;
          border-radius: 0.5em;
          color: $White-Color;
          border-style: none;
        }

        .link-blue {
          background-color: $theme-blue;
        }
        .link-gray {
          background-color: $theme-light-gray;
        }

        .status {
          padding: 0.5em 1em;
          border-radius: 0.5em;
        }
        .green {
          background-color: $theme-green-background;
          color: $theme-light-green;
        }
        .red {
          background-color: $theme-red-background;
          color: $theme-red;
        }
        .yellow {
          background-color: $theme-light-yellow;
          color: $theme-yellow;
        }
        .gray {
          background-color: $theme-light-gray;
          color: $theme-subuser;
        }
      }
    }
  }
}

.meeting-btn {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: $theme-light-blue;
  margin: 0 2.5px;
  .meeting-btn-img {
    width: 50%;
    height: auto;
  }
}
.green-clr {
  color: #00c851;
}
.cancel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  color: #133f7d;
  width: 110px;
  background-color: #f3f3f9;
  border: 1px solid #133f7d;
  height: 35px;
}
.save-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
  padding: 5px 10px;
  color: white;
  width: 120px;
  background-color: #133f7d;
}
.grey-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-style: none;
  color: white;
  width: 110px;
  background-color: #626262;
}
