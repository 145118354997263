.c-label {
  font-family: "Manrope-SemiBold";
  color: $theme-black !important;
  margin-bottom: 2px;
}

.c-label-login {
  padding-left: 30px;
}

.c-input-container {
  border: 1px solid $theme-gray;
  overflow: hidden;
}

.form-check-label {
  margin-left: 4px;
}

.c-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 36px;
  border: none;
  padding: 1.2em;
  border-radius: 0.5em;
  font-family: "Manrope-Regular";
  box-shadow: none !important;
  outline: none !important;
  background-color: $White-Color;

  &::placeholder {
    color: $theme-placeholder;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}

.c-input-login {
  padding: 0em 1em;
  // padding: 0.3em 1em;
  border-radius: 40px;
  @media screen and (max-width: 992px) {
    padding: 0.3em 1em;
  }
}

.error {
  border: none !important;
}

input[type="checkbox"] {
  accent-color: #1c4784 !important;
  height: 1rem;
  width: 1rem;
}

input[type="file"] {
  &::-webkit-file-upload-button {
    display: none;
  }
}
.login-c-input {
  padding: 10px !important;
}
.common-input {
  width: 100%;
  border: none;
  padding: 1.2em;
  outline: none !important;
  background-color: $White-Color;
  cursor: pointer;
  &::placeholder {
    color: $theme-placeholder;
  }
  &:disabled {
    background-color: #e7e7e7;
    color: #626262;
    cursor: not-allowed;
  }
}

.date-input-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-left: 10px;
  color: $theme-placeholder !important;
}

// country code input css
// .flag-dropdown {
//   border: none !important;
//   background-color: transparent !important;
//   border-radius: var(--bs-border-radius) !important;
// }
// .flag-dropdown.open {
//   height: 100% !important;
//   border-radius: none !important;
//   border-radius: var(--bs-border-radius) !important;
// }
.react-tel-input {
  outline: none !important;
}
.flag-dropdown {
  border: none !important;
  background-color: transparent !important;
}
.form-control {
  padding-top: 1.45rem !important;
  padding-bottom: 1.45rem !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  &:disabled {
    background-color: #e7e7e7 !important;
    color: #626262 !important;
  }
}
// country code input css

//common-date-picker css//
.common-date-picker {
  width: 115px;
  position: relative;
  .date-picker-arrow {
    position: absolute;
    top: 15px;
    right: 10px;
  }
  .date-picker {
    padding: 10px;
    font-size: 12px;
    width: 100%;
    border: none;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: black;
    }
  }
}
//common-date-picker css//

.text-area {
  resize: none !important;
  height: 100px;
  border-radius: 5px !important;
}
