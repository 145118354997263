.footer {
  color: #626262;
  // padding: 10px 28px;
  padding-left: 28px;
  background-color: #f3f3f9;
}
.footer-text {
  color: #1c4784;
  cursor: pointer;
  text-decoration: none;
}
