.page-container {
  // overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $theme-blue;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $theme-blue;
  }
  .dp-productcard-container {
    margin: 2em 0;
  }
  .dashProductCard {
    background-color: $White-Color;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    padding: 1em;
    width: 230px;
    border-radius: 0.4em;
    @media screen and (max-width: 992px) {
      margin: 10px 20px;
    }
    .dpc-row1 {
      margin-bottom: 10px;

      .dpc-title {
        font-size: 1em;
      }
      .dpc-subtitle {
        font-size: 0.5em;
      }
    }
    .dpc-row2 {
      margin-top: 10px;
      font-size: 0.8em;
    }
    .dpc-img {
      height: 2em;
    }
  }
  .dp-statcard-container {
    margin: 5em 0 2em 0;
    .dashStatCard {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      padding: 1em;
      width: 230px;
      border-radius: 0.4em;
      background-color: $White-Color;
      color: $theme-subtitle-color;
      @media screen and (max-width: 992px) {
        margin: 20px;
      }
      .dsc-title {
        color: $theme-border;
        font-size: 0.8em;
        .dsc-number {
          font-size: 1.5em;
          color: $theme-blue;
        }
      }

      .dsc-divider {
        background-color: $theme-border;
        height: 0.5px;
        margin: 15px 0;
      }
      .dsc-subtitle {
        color: $theme-border;
        font-size: 0.8em;

        .dsc-percent {
          color: $theme-light-green;
        }
      }
      .dsc-poster {
        bottom: 70%;
        left: 7%;
        width: 4em;
      }
    }
  }
  .progress-chart-container {
    background-color: $White-Color;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  #dashboardDropdown {
    width: auto;
    height: 40px;
    background: $White-Color;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    .dd-options {
      padding: 10px 0;
    }
  }
}

.dp-graph-container {
  height: 500px;
  background-color: $White-Color;
  padding: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  width: calc(50% - 10px);
}
.dp-graph-container2 {
  height: 500px;
  background-color: $White-Color;
  padding: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
}
