body {
  margin: 0 !important;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  word-break: break-all;
}
code {
  font-family: "Manrope";
}
@page {
  size: A4;
  margin: 0mm;
}
.feedback-type-1 {
  width: 100vw;
  .radar-graph-labels-main {
    text-align: left;
    width: 120px;
    margin: 0 auto;
  }
  .profile-div {
    background: #133f7d;
    display: flex;
    min-height: 220px;
    align-items: center;
  }
  .profile-img-div {
    background: #d7eeff;
    width: 220px;
    height: 220px;
    margin: 0px 25px;
    display: flex;
    align-items: center;
  }
  .graph-title {
    margin: 6px 0px !important;
  }
  .profile-img-logo {
    width: 220px;
    height: 220px;
    border-radius: 50%;
  }

  .common-profile-text {
    color: #ffffff;
    font-style: normal;
  }
  .profile-details-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    padding: 2px;
    display: flex;
  }
  .radar-graph-label-color {
    border-radius: 2px;
    height: 15px;
    margin-right: 10px;
    width: 15px;
    display: inline-block;
  }
  .radar-graph-label-name {
    display: inline-block;
    width: 80px;
  }
  .blue {
    background-color: rgba(19, 63, 125, 0.4);
  }
  .newgreen {
    background-color: rgba(17, 178, 184, 0.4);
  }
  .profile-details-status {
    position: relative;
    width: 100%;
    height: 220px;
  }

  .profile-contact {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .profile-intId {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
  }

  .profile-recommended-img {
    width: 400px;
    position: absolute;
    right: 0;
    top: 15px;
    height: auto;
  }
  .profile-recommended {
    font-weight: 700;
    color: #ffffff !important;
    font-size: 22px;
    text-align: right;
    letter-spacing: 0.04em;
    width: 400px;
    position: absolute;
    right: 20px;
    line-height: 52.5px;
    top: 15px;
  }
  .profile-details-div {
    width: 100%;
    padding: 10px;
  }

  .profile-suspected {
    width: 120px;
    position: absolute;
    right: 70%;
    bottom: 20px;
  }

  .detailed-feedback-title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 66px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #133f7d;
  }

  .feedback-wrapper {
    background-color: #f5f5f5;
  }

  .pagebreak-after {
    page-break-after: always;
  }

  .pagebreak {
    page-break-before: always;
    padding: 10px;
  }

  .feedback-header {
    padding-top: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 2.8vw;
    line-height: 66px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #133f7d;
  }

  .font-style-detailed-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: black;
  }

  .font-style-header-detailed {
    background-color: #133f7d;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vw;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: white;
  }

  .mandatory-skills-wrapper {
    margin: 24px 40px;
    background-color: white;
    display: flex;
  }

  .mandatory-skills-outer-wrapper {
    background-color: #133f7d;
    min-height: 400px;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-1 {
    transform: rotate(-90deg);
    white-space: nowrap;
  }

  .title-600 {
    padding: 10px;
    display: none !important;
  }

  .mandatory-skills-content {
    padding: 10px 10px;
    padding-left: 30px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
  }

  .opyional-skills-wrapper {
    background-color: white;
    margin: 24px 40px;
    display: flex;
  }

  .opyional-skills-content {
    padding-left: 30px;
    padding-top: 10px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
  }

  .optional-skills-outer-wrapper {
    background-color: #133f7d;
    min-height: 400px;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .optional-skills-outer-wrapper-600 {
    display: none !important;
    padding: 10px;
  }

  .optional-skills-header {
    transform: rotate(90deg);
    white-space: nowrap;
  }

  .container-1 {
    border: 2px solid red;
    display: flex;
    height: 400px;
    width: 100%;
  }

  .con-1 {
    border: 2px solid blue;
    height: 100%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .con-2 {
    border: 2px solid green;
    height: 100%;
    width: 85%;
  }

  .font-styling-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #000000;
  }

  .font-style-header-code-snippet {
    background-color: #133f7d;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vw;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.04em;
    color: white;
  }

  .code-snippet-wrapper {
    background-color: #f5f5f5;
    padding-bottom: 20px;
  }

  .code-snippet-header {
    background-color: #133f7d;
    color: white;
    margin-top: 24px;
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    width: 45%;
    padding: 10px 10px 10px 7%;
  }

  .code-snippet-span {
    font-weight: 600;
    background-color: #133f7d;
    font-style: normal;
    font-size: 2.8vw;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #ffffff;
  }

  .code-content {
    background: #d9d9d9;
    border-radius: 50px;
    margin: 24px 40px;
    padding: 40px 60px 40px 89px;
  }

  .code-content-pre {
    width: 100%;
    overflow-x: scroll;
  }

  .final-remark-wrapper {
    margin: 24px 40px;
    padding: 10px;
    display: flex;
  }
  .vertical-left-header {
    background-color: #133f7d;
    min-height: 300px;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .final-remark-header {
    transform: rotate(-90deg);
    white-space: nowrap;
  }

  .final-remark-header-600 {
    display: none !important;
    padding: 5px;
  }

  .final-remark-content {
    background-color: white;
    padding-left: 10px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
  }

  .final-remark-content-span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #000000;
    padding: 20px;
  }

  .screenshots-header {
    background-color: #133f7d;
    padding: 10px 10px 10px 7%;
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    width: 40%;
  }

  .screenshots {
    height: 100%;
    width: 100%;
  }

  .feedback-footer {
    display: flex;
  }

  .footer-logo {
    height: 69px;
    margin: auto;
    width: 98px;
  }

  .screenshot-wrapper {
    padding-top: 20px;
    position: relative;
    width: 92%;
    margin: 0px 4%;
  }
  .screenshot-wrapper img {
    width: 100%;
  }

  .fill-img1 {
    height: 72%;
    left: 12%;
    position: absolute;
    top: 12%;
    width: 76%;
  }

  .wrapper {
    background: #f5f5f5;
  }

  .font-style-soft-skill {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #000000;
    margin: 10px 0px;
  }

  .font-style-header-soft-skill {
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vw;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #ffffff;
    background: #133f7d;
  }

  .soft-skill-wrapper {
    margin: 24px 40px;
    display: flex;
    min-height: 400px;
  }
  .soft-skill-wrapperv2 {
    margin: 24px 40px;
    display: flex;
    min-height: 400px;
  }

  .soft-skill-header {
    transform: rotate(90deg);
    white-space: nowrap;
  }

  .soft-skill-header-600 {
    display: none;
    padding: 5px;
  }

  .soft-skill-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px 10px 30px;
    text-align: start;
    width: 92%;
    background: white;
  }

  .soft-skill-header-outer {
    background-color: #133f7d;
    min-height: 300px;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .soft-skill-header-inner {
    padding: 50% 0;
    margin: 0;
  }

  .psycological-skill-wrapper {
    margin: 24px 40px;
    padding: 10px;
    display: flex;
  }

  .psycological-skill-content {
    padding: 15px 10px 22px 10px;
    background-color: white;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
  }

  .psycological-skill-header-outer {
    background-color: #133f7d;
    min-height: 350px;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .psycological-skill-header {
    transform: rotate(90deg);
    white-space: nowrap;
  }

  .psycological-skill-header-600 {
    display: none !important;
    padding: 5px;
  }

  .screening-ques-wrapper {
    margin: 24px 0px;
    background-color: #f5f5f5;
    padding-bottom: 1px;
  }

  .font-style-header-screening {
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vw;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #ffffff;
  }

  .screening-ques-header {
    background-color: #133f7d;
    padding: 10px 10px 10px 7%;
    text-align: left;
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    width: 45%;
  }

  .ques-ans-wrapper {
    margin: 20px 40px;
  }

  .ques {
    background: #cadff5;
    padding: 15px;
    width: 70%;
    font-weight: bold;
  }

  .ans {
    padding: 15px;
    background-color: white;
    margin: 15px 0px;
  }

  .header {
    display: flex;
    padding: 10px 30px;
  }

  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .pagebreak-after {
    page-break-after: always;
  }

  .pagebreak {
    page-break-before: always;
  }

  .img-logo {
    width: 180px;
    height: auto;
  }
  .text-center {
    text-align: center !important;
  }
  .img-div {
    width: 50%;
  }

  .header-content-wrapper {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .blank-div {
    height: 72px;
    width: 100%;
    background-color: white;
  }

  .font-style-header {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #ffffff;
  }

  .font-style {
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.04em;
    color: black;
  }

  .content-div {
    height: 24%;
    width: 100%;
    background-color: #133f7d;
    padding: 10px 10px 10px 9%;
    clip-path: polygon(100% 100%, 0% 100%, 10% 0%, 100% 0%);
  }

  .content-div-span {
    margin-left: 15%;
  }

  .btn-g {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    background: #11b2b8;
    border-radius: 5px;
    border: none;
    right: 10px;
    position: relative;
    width: 80px;
  }

  .report-wrapper {
    background-color: #f5f5f5;
  }

  .section {
    display: flex;

    position: relative;
    justify-content: space-between;
  }

  .tech-details-wrapper {
    display: flex;
    width: 50%;
  }

  .tech-details {
    width: 50%;
    position: absolute;
    top: 36%;
    left: 2%;
  }

  .profile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 30px;
  }

  .font-syle-profile {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }

  .font-style-profile-span {
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    color: #626262;
  }

  .profile-details {
    position: absolute;
    top: 77%;
    left: 21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7px;
  }

  .profile-details-p1 {
    color: #626262;
  }

  .profile-details-p2 {
    color: #133f7d;
    font-weight: 1000;
    font-size: 16px;
  }

  .profile-details-p3 {
    color: #000000;
    font-weight: 600;
  }

  .arrow-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 25px;
    margin-top: 27px;
  }

  .arrow-img {
    position: relative;
    right: 22%;
    width: 6%;
  }

  .recommendation {
    display: flex;
    margin-top: 5px;
  }

  .recommendation-span {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6vw;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.04em;
    display: table-cell;
    vertical-align: middle;
    color: #ffffff;
  }

  .recommendation-div {
    width: 27%;
    height: 46px;
    display: table;
    border-radius: 13px;
    position: absolute;
    bottom: 50;
  }

  .red {
    background-color: #e96f68;
    left: 10%;
    z-index: 2;
  }

  .yellow {
    left: 35%;
    background: #f5e659;
    z-index: 1;
  }

  .green {
    margin-left: 59%;
    z-index: 0;
    background: #61be52;
  }

  .candidate-overall-skills {
    display: flex;
    margin-top: 88px;
  }

  .candidate-skills-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 50px 50px 0px;
    text-align: center;
    width: 50%;
    height: 600px;
  }
  .toplayer-ellip-img {
    width: 150px;
    height: 150px;
  }

  .candidate-skills {
    height: 100%;
    width: 100%;
    text-align: center;
    margin: 15px auto;
    display: flex;
    max-width: 450px;
    max-height: 450px;
    align-items: center;
    justify-content: center;
  }

  .overall-skills-div {
    display: flex;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    margin: 60px 0px;
  }

  .candidate-skills-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #133f7d;
    padding-top: 20px;
    display: block;
  }

  .overAll-skills-header {
    width: 100%;
    height: 70px;
    clip-path: polygon(0 2%, 19% 197%, 353% 0%, 0% 0%);
    position: relative;
    background-color: #133f7d;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .overAll-skills {
    width: 50%;
    height: 600px;
  }

  .mobile-mode {
    display: none;
  }
  .desktop-mode {
    display: block;
  }

  .overAll-skills-span {
    margin-right: 10%;
  }
  .CircularProgressbar {
    width: 100px;
    text-align: center;
  }

  .CircularProgressbarDiv {
    position: relative;
    right: 50px;
    text-align: right;
    top: 100px;
  }

  .CircularProgressbarText {
    color: #fff;
    position: absolute;
    right: 38px;
    text-align: center;
    top: 14px;
    font-size: 30px;
  }

  .CircularProgressbarText p {
    margin: 0px;
    font-size: 14px;
  }

  .toplayer-ellip-div {
    padding-top: 25px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .toplayer-ellip-title {
    margin: 2.5px 5px;
    font-weight: 600;
  }

  .toplayer-ellip-percent {
    margin: 2.5px 5px;
    font-weight: 600;
    color: #133f7d;
  }

  .font-style-percent {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
  }

  .vector-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .vector-tl-div {
    width: 40%;
  }

  .vector-center-div {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .vector-center {
    width: 140px;
  }

  .secondlayer-ellip {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    width: 90%;
    margin-top: 20px;
  }

  .secondlayer-ellip-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: red;
  }

  .secondlayer-ellip-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ellip-layer-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .soft-phychological-skills {
    background-color: #f5f5f5;
    margin-top: 60px;
    display: flex;
  }

  .chart-wrapper {
    width: 200px;
    height: 200px;
    display: inline-block;
  }

  .rating-div {
    width: 150px;
    overflow: clip;
    display: inline-block;
    margin-left: 30px;
  }

  .soft-skills {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .m1-img {
    position: absolute;
    left: 40%;
    top: 10%;
    width: 80px;
  }
  .m2-img {
    position: absolute;
    top: 46%;
    left: 46%;
    width: 57px;
  }
  .m3-img {
    left: 33%;
    position: absolute;
    top: 75%;
    width: 105px;
  }

  .marking-rating-name,
  .marking-rating-namev2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #000000;
    margin: 10px 5px;
    text-align: start;
    height: 50px;
    display: block;
  }

  .marking-rating-span {
    color: #133f7d;
  }

  .soft-skills-wrapper,
  .phychological-skills-wrapper,
  .leadership-skills-wrapper {
    width: 49%;
    display: inline-block;
  }

  .space-2 {
    margin: 40px;
    display: flex;
  }

  .soft-skills-wrapperv2,
  .phychological-skills-wrapperv2,
  .leadership-skills-wrapperv2 {
    width: 32%;
    display: inline-block;
  }

  .soft-skills-graph-wrapper {
    background-color: #e7ebfb;
    padding: 20px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .screenshots-container {
    display: block;
  }

  .soft-skills-div {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    background-color: #133f7d;
    align-items: center;
    display: flex;
    width: 100%;
    height: 70px;
  }

  .leadership-skills-div {
    clip-path: polygon(0% 100%, 15% 0%, 100% 0%, 100% 100%, 0% 100%);
    background-color: #133f7d;
    align-items: center;
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: flex-end;
  }

  .phychological-skills-divv2 {
    clip-path: polygon(0% 0%, 12% 100%, 100% 100%, 88% 100%, 100% 0%);
    position: relative;
    background-color: #133f7d;
    align-items: center;
    display: flex;
    height: 70px;
    width: 130%;
    right: 15%;
    justify-content: center;
  }
  .skills-div {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    background-color: #133f7d;
    align-items: center;
    display: flex;
    width: 50%;
    height: 70px;
  }

  .soft-skills-span {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-left: 10%;
  }

  .leadership-skills-span {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-right: 10%;
  }

  .phychological-skills-span {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-right: 10%;
  }

  .phychological-skills-spanv2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #ffffff;
  }

  .phychological-skills-div {
    clip-path: polygon(0% 0%, 13% 100%, 100% 100%, 100% 0%);
    position: relative;
    background-color: #133f7d;
    align-items: center;
    display: flex;
    height: 70px;
    width: 115%;
    right: 15%;
    justify-content: flex-end;
  }

  .other-skills {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  .other-skillsv2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  .skill-rating-div,
  .detailed-feedback,
  .screening-ques-wrapper,
  .code-snippet-wrapper,
  .detailed-feedback-list {
    margin-bottom: 60px;
  }

  .psychological-skills-span {
    color: white;
    background-color: #133f7d;
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vw;
    line-height: 44px;
    letter-spacing: 0.04em;
    margin: 10px 8% 10px 10px;
  }

  .profilepic {
    width: 90%;
  }

  .rating-button {
    border-radius: 13px;
    margin: 10px !important;
    padding: 20px !important;
  }

  .rating-arrow {
    border: 1.5px solid #133f7d;
    height: 100%;
    width: 10px;
    border-radius: 10px;
    position: relative;
  }

  .rating-arrow-tab {
    top: 80%;
    width: 35px;
    height: 25px;
    position: absolute;
  }

  .rating-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
  }

  .mobile-recommendation-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
  }

  .recommendation-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #626262;
  }
  .secondlayer-ellip .ellip-div {
    width: 33%;
  }

  .toplayer-ellip {
    padding-top: 40px;
    text-align: center;
  }

  .individual-graph-wrapper {
    width: 25%;
    display: inline-block;
    padding: 40px 20px;
    text-align: center;
  }

  .mandatory-skills-graph-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 76px;
    margin: 25px;
    padding: 20px;
    text-align: center;
  }

  .graph-title {
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #000000;
  }
  .graph-descp-comment {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
  }
  .other-skillsv2 .m1-img {
    left: 150px;
    top: 12%;
    width: 54px;
  }

  .other-skillsv2 .m2-img {
    left: 173px;
    top: 45%;
    width: 31px;
  }
  .other-skillsv2 .m3-img {
    position: absolute;
    width: 60px;
    width: 82px;
    top: 75%;
  }

  .status-green {
    color: #3a992b;
  }

  .status-yellow {
    color: #b7bb0b;
  }

  .status-red {
    color: #c55d56;
  }

  .App {
    background: #f5f5f5;
  }

  @media print {
    html,
    body {
      width: 210mm;
      height: 297mm;
    }
    .break-stop {
      page-break-inside: avoid;
      break-inside: avoid;
      padding: 5px;
    }
    .App {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: #f5f5f5;
      page-break-after: always;
    }
    .rating-div2 {
      display: inline-block;
      margin-left: 0px;
      overflow: clip;
      width: 100px;
    }

    .btn-g {
      display: none;
    }
    .skill-rating-div div,
    .detailed-feedback,
    .screenshots-wrapper,
    .code-snippet-wrapper {
      margin-top: 12px;
    }
    .img-logo {
      width: 129px;
      height: 59px;
    }
    .pagebreak {
      page-break-before: always;
    }
    .soft-skills-graph-wrapper {
      height: 220px;
    }
    .profile-div {
      min-height: 170px;
    }
    .overAll-skills-header {
      height: 50px;
    }
    .m1-img {
      position: absolute;
      left: 45%;
      top: 15%;
      width: 80px;
    }
    .mobile-mode {
      display: none;
    }

    .desktop-mode {
      display: block;
    }
    .candidate-skills canvas {
      height: 300px !important;
      width: 300px !important;
    }
    .profile-img-div {
      width: 170px;
      height: 170px;
    }
    .toplayer-ellip-img {
      width: 80px;
      height: 80px;
    }
    .profile-img-logo {
      width: 170px;
      height: 170px;
    }
    .profile-details-status {
      height: 170px;
    }
    .profile-suspected {
      width: 90px;
      right: 70%;
      bottom: 20px;
    }
    .code-content-pre {
      overflow-x: hidden;
    }
    .screenshots-container {
      display: block;
    }
    .screenshot-wrapper {
      display: inline-block;
    }
    .candidate-skills {
      width: 350px;
      height: 350px;
      max-width: 350px;
      max-height: 350px;
    }
    .profile-recommended-img {
      width: 250px;
    }
    .profile-recommended {
      font-size: 14px;
      width: 250px;
      line-height: 36.93px;
    }
    .candidate-skills-wrapper {
      height: 500px;
      width: 50%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 25px 25px 0px;
    }
    .mandatory-skills-graph-wrapper {
      border-radius: 25px;
    }
    .overall-skills-div {
      min-height: 550px;
      margin: 0px;
    }
    .soft-skill-content {
      padding: 10px 20px;
      width: 100%;
    }
    .candidate-skills {
      height: 80%;
    }
    .space-2 {
      margin: 10px;
    }
    .vector-center {
      margin-top: -25px;
    }
    .soft-skills-span,
    .phychological-skills-span,
    .phychological-skills-spanv2,
    .leadership-skills-span {
      font-size: 18px;
    }
    .font-style-header,
    .candidate-skills-heading {
      font-size: 24px;
      line-height: 30px;
    }
    .chart-wrapper,
    .chart-wrapper canvas {
      width: 150px !important;
      height: 150px !important;
      display: inline-block;
    }
    .overAll-skills {
      height: 500px;
    }
    .font-style-percent {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .phychological-skills-div,
    .phychological-skills-divv2,
    .soft-skills-div,
    .leadership-skills-div,
    .skills-div {
      height: 40px;
    }

    .graph-img {
      width: 80px;
    }
    .graph-title,
    .graph-descp-comment {
      font-size: 14px;
      line-height: 22px;
    }
    .individual-graph-wrapper {
      display: inline-block;
      padding: 1.5%;
      text-align: center;
      width: 33%;
    }
    .detailed-feedback-title {
      font-size: 24px;
      line-height: 33px;
    }
    .soft-skill-header {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.04em;
    }
    .font-style-soft-skill {
      font-size: 16px;
      letter-spacing: 0.04em;
    }
    .code-content {
      font-size: 14px;
      line-height: 19px;
    }
    .soft-skill-wrapper,
    .soft-skill-wrapperv2 {
      min-height: 300px;
      display: block;
    }
    .soft-skill-header-outer {
      display: none;
    }
    .soft-skill-header-600 {
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
    .profile-details-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .profile-intId {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }
    .profile-contact {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .skill-rating-div,
    .detailed-feedback,
    .screening-ques-wrapper,
    .code-snippet-wrapper,
    .detailed-feedback-list {
      margin-bottom: 0px;
    }
    .other-skillsv2 .m1-img {
      left: 115px;
      top: 15%;
      -webkit-transform: rotate(-25deg);
      transform: rotate(-25deg);
      width: 38px;
    }
    .other-skillsv2 .m2-img {
      left: 128px;
      top: 45%;
      width: 25px;
    }
    .other-skillsv2 .m3-img {
      left: 109px;
      top: 71%;
      width: 47px;
      -webkit-transform: rotate(-40deg);
      transform: rotate(40deg);
    }
    .other-skills .m1-img {
      left: 37%;
      top: 23%;
      width: 60px;
    }
    .other-skills .m2-img {
      left: 39%;
      top: 46%;
      width: 53px;
    }
    .other-skills .m3-img {
      left: 26%;
      top: 71%;
      width: 95px;
    }
    .CircularProgressbar {
      width: 80px;
      text-align: center;
    }

    .CircularProgressbarDiv {
      position: relative;
      right: 50px;
      text-align: right;
      top: 70px;
    }

    .CircularProgressbarText {
      color: #fff;
      position: absolute;
      right: 32px;
      text-align: center;
      top: 12px;
      font-size: 24px;
    }

    .CircularProgressbarText p {
      margin: 0px;
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .skill-rating-div div,
    .detailed-feedback,
    .screenshots-wrapper,
    .code-snippet-wrapper {
      margin-top: 15px;
    }
    .phychological-skills-divv2,
    .phychological-skills-div {
      width: 60%;
      margin-bottom: 10px;
      clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
      background-color: #133f7d;
      align-items: center;
      display: flex;
      left: 0px;
      justify-content: flex-start;
    }
    .leadership-skills-span {
      margin-left: 10%;
      margin-right: auto;
    }
    .phychological-skills-span,
    .phychological-skills-spanv2 {
      margin-left: 10%;
      text-align: left;
    }
    .img-logo {
      width: 129px;
      height: 59px;
    }
    .pagebreak {
      page-break-before: always;
    }
    .soft-skills-graph-wrapper {
      height: 220px;
    }
    .profile-div {
      min-height: 170px;
    }
    .overAll-skills-header {
      height: 50px;
    }
    .candidate-skills canvas {
      height: 300px !important;
      width: 300px !important;
    }
    .profile-img-div {
      width: 170px;
      height: 170px;
    }
    .toplayer-ellip-img {
      width: 80px;
      height: 80px;
    }
    .profile-img-logo {
      width: 170px;
      height: 170px;
    }
    .profile-details-status {
      height: 170px;
    }
    .profile-suspected {
      width: 90px;
      right: 70%;
      bottom: 20px;
    }
    .code-content-pre {
      overflow-x: hidden;
    }
    .screenshots-container {
      display: block;
    }
    .screenshot-wrapper {
      width: 50%;
      display: inline-block;
    }
    .candidate-skills {
      width: 350px;
      height: 350px;
      max-width: 350px;
      max-height: 350px;
    }
    .profile-recommended-img {
      width: 280px;
    }
    .profile-recommended {
      font-size: 16px;
      width: 280px;
      line-height: 36.93px;
    }
    .candidate-skills-wrapper {
      height: 500px;
      width: 50%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 25px 25px 0px;
    }
    .mandatory-skills-graph-wrapper {
      border-radius: 25px;
    }
    .overall-skills-div {
      min-height: 550px;
      margin: 0px;
    }
    .candidate-skills {
      height: 80%;
    }
    .space-2 {
      margin: 10px;
    }
    .vector-center {
      margin-top: -25px;
    }
    .soft-skills-span,
    .phychological-skills-span,
    .phychological-skills-spanv2,
    .leadership-skills-span {
      font-size: 18px;
    }
    .font-style-header,
    .candidate-skills-heading {
      font-size: 24px;
      line-height: 30px;
    }
    .overAll-skills {
      height: 500px;
    }
    .font-style-percent {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .phychological-skills-div,
    .phychological-skills-divv2,
    .soft-skills-div,
    .leadership-skills-div,
    .skills-div {
      height: 40px;
    }

    .graph-img {
      width: 80px;
    }
    .graph-title,
    .graph-descp-comment {
      font-size: 14px;
      line-height: 22px;
    }
    .individual-graph-wrapper {
      display: inline-block;
      padding: 2%;
      text-align: center;
      width: 33%;
    }
    .detailed-feedback-title {
      font-size: 24px;
      line-height: 33px;
    }
    .soft-skill-header {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.04em;
    }
    .font-style-soft-skill {
      font-size: 16px;
      letter-spacing: 0.04em;
    }
    .code-content {
      font-size: 14px;
      line-height: 19px;
    }
    .soft-skill-wrapper {
      min-height: 300px;
    }
    .soft-skill-wrapperv2 {
      min-height: 300px;
    }
    .profile-details-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .profile-intId {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }
    .profile-contact {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .skill-rating-div,
    .detailed-feedback,
    .screening-ques-wrapper,
    .code-snippet-wrapper,
    .detailed-feedback-list {
      margin-bottom: 0px;
    }
    .soft-skills-wrapperv2,
    .leadership-skills-wrapperv2 {
      width: 100%;
      display: inline-block;
      margin: 20px 0px;
    }

    .phychological-skills-wrapperv2,
    .phychological-skills-wrapper {
      width: 100%;
      display: inline-block;
    }
    .other-skills,
    .other-skillsv2 {
      display: block;
      margin-bottom: 0px;
    }
    .rating-div2 {
      display: inline-block;
      margin-left: 30px;
      overflow: clip;
      width: 180px;
    }
    .other-skillsv2 .m1-img {
      width: 80px;
      top: 9%;
    }
    .other-skillsv2 .m2-img {
      width: 57px;
    }
    .other-skillsv2 .m3-img {
      top: 77%;
      width: 109px;
    }
    .soft-skills-div {
      height: 40px;
      width: 60%;
      margin-bottom: 10px;
    }
    .leadership-skills-div {
      height: 40px;
      width: 60%;
      margin-bottom: 10px;
      clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    }
    .soft-skills-wrapper {
      width: 100%;
      display: inline-block;
      margin: 20px 0px;
    }
    .CircularProgressbar {
      width: 80px;
      text-align: center;
    }
    .CircularProgressbarDiv {
      position: relative;
      right: 50px;
      text-align: right;
      top: 70px;
    }
    .CircularProgressbarText {
      color: #fff;
      position: absolute;
      right: 32px;
      text-align: center;
      top: 12px;
      font-size: 24px;
    }
    .CircularProgressbarText p {
      margin: 0px;
      font-size: 10px;
    }
    .mobile-mode {
      display: none;
    }

    .desktop-mode {
      display: block;
    }
  }

  @media only screen and (max-width: 767px) {
    .btn-g {
      width: 46px;
      height: 22px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      right: 0;
    }
    .mobile-mode {
      display: flex !important;
    }
    .desktop-mode {
      display: none !important;
    }
    .vector-center {
      margin-top: -30px;
    }
    .skills-div {
      display: block;
    }
    .skills-div .soft-skills-span {
      margin-left: 0px;
    }
    .phychological-skills-divv2,
    .phychological-skills-div {
      height: 40px;
      width: 60%;
      margin-bottom: 10px;
      clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
      background-color: #133f7d;
      align-items: center;
      display: flex;
      left: 0px;
      justify-content: flex-start;
    }
    .leadership-skills-span {
      margin-left: 10%;
      margin-right: auto;
    }
    .leadership-skills-div {
      clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
      justify-content: flex-start;
    }
    .img-logo {
      width: 53px;
      height: 33px;
    }
    .profile-recommended-img {
      width: 200px;
    }
    .profile-img-logo {
      width: 120px;
    }
    .profile-img-logo,
    .profile-img-div,
    .profile-details-status {
      height: 120px;
      justify-content: center;
    }
    .profile-suspected {
      width: 60px;
      right: 50%;
      bottom: 15px;
    }
    .profile-div {
      min-height: 120px;
    }
    .profile-recommended {
      font-size: 10.5px;
      width: 200px;
      line-height: 26.38px;
    }
    .candidate-skills-wrapper {
      height: 350px;
      width: 92%;
      margin: 4%;
      border-radius: 50px;
    }
    .soft-skills-graph-wrapper {
      width: 92%;
      margin: 4%;
    }
    .chart-wrapper {
      width: 200px;
      height: 200px;
    }
    .overall-skills-div {
      display: block;
      min-height: 350px;
      margin: 0px;
    }
    .candidate-skills {
      height: 80%;
    }
    .overAll-skills {
      height: 380px;
      width: 92%;
      margin: 4%;
    }
    .space-2 {
      margin: 0px;
    }
    .toplayer-ellip-img {
      width: 70px;
      height: 70px;
    }
    .font-style-percent {
      font-size: 14px;
      line-height: 19px;
    }
    .soft-skills-span,
    .phychological-skills-spanv2,
    .phychological-skills-span,
    .leadership-skills-span {
      line-height: 40px;
      font-size: 16px;
    }
    .phychological-skills-spanv2,
    .phychological-skills-span {
      text-align: left;
      margin-left: 10%;
    }
    .soft-skills-div {
      height: 40px;
      width: 60%;
      margin-bottom: 10px;
    }
    .leadership-skills-div {
      height: 40px;
      width: 60%;
      margin-bottom: 10px;
      clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    }
    .skills-div {
      height: 40px;
      width: 92%;
      margin: 4%;
      margin-bottom: 10px;
      clip-path: none;
    }

    .skills-div {
      text-align: center;
      margin: 0 auto;
    }
    .font-style-header,
    .candidate-skills-heading {
      font-size: 16px;
      line-height: 22px;
    }
    .other-skills,
    .other-skillsv2 {
      display: block;
      margin-bottom: 0px;
    }
    .overAll-skills-header {
      height: 40px;
      justify-content: center;
      clip-path: none;
      margin-right: inherit;
    }
    .graph-img {
      width: 80px;
    }
    .graph-title,
    .graph-descp-comment {
      font-size: 10px;
      line-height: 14px;
    }
    .individual-graph-wrapper {
      width: 33%;
      display: inline-block;
      padding: 3%;
      text-align: center;
    }
    .detailed-feedback-title {
      font-size: 24px;
      line-height: 33px;
    }
    .soft-skill-header {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.04em;
    }
    .font-style-soft-skill {
      font-size: 16px;
      letter-spacing: 0.04em;
      width: 100%;
      padding: 2%;
    }
    .code-content {
      font-size: 14px;
      line-height: 19px;
    }
    .soft-skills-wrapper {
      width: 100%;
      display: inline-block;
      margin: 20px 0px;
    }
    .soft-skills-wrapperv2,
    .leadership-skills-wrapperv2 {
      width: 100%;
      display: inline-block;
      margin: 20px 0px;
    }

    .phychological-skills-wrapperv2,
    .phychological-skills-wrapper {
      width: 100%;
      display: inline-block;
    }
    .soft-skill-wrapper {
      min-height: auto;
      display: block;
      margin: 25px;
    }
    .soft-skill-wrapperv2 {
      min-height: auto;
      display: block;
      margin: 25px;
    }
    .screenshots-container {
      display: block;
    }
    .soft-skill-header-outer {
      display: none;
    }
    .soft-skill-header-600 {
      display: block;
      height: 40px;
      width: 100%;
      margin-bottom: 10px;
      clip-path: none;
      padding: 0px;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
    }
    .soft-skill-content {
      padding: 10px 20px;
      width: 100%;
    }
    .profile-details-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
    .ques-ans-wrapper {
      margin: 4%;
    }
    .profile-intId {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }
    .profile-contact {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
    }
    .overAll-skills-span {
      margin-right: inherit;
    }
    .common-profile-text {
      color: #0d0d0d;
    }
    .screenshot-wrapper {
      width: 92%;
      padding: 4%;
      margin: 0px auto;
    }
    .fill-img1 {
      height: 67%;
      left: 15%;
      position: absolute;
      top: 11%;
      width: 70%;
    }
    .profile-details-div {
      width: 92%;
      padding: 4%;
      justify-content: space-around;
      display: flex;
    }
    .skill-rating-div,
    .detailed-feedback,
    .screening-ques-wrapper,
    .code-snippet-wrapper,
    .detailed-feedback-list {
      margin-bottom: 0px;
    }
    .rating-div2 {
      display: inline-block;
      margin-left: 30px;
      overflow: clip;
      width: 150px;
    }
    .other-skillsv2 .m1-img {
      width: 80px;
    }
    .other-skillsv2 .m2-img {
      width: 57px;
    }
    .other-skillsv2 .m3-img {
      width: 110px;
    }
    .other-skills .m1-img {
      left: 150px;
      top: 12%;
      width: 75px;
    }

    .other-skills .m2-img {
      left: 173px;
      top: 45%;
      width: 52px;
    }
    .other-skills .m3-img {
      position: absolute;
      width: 105px;
      left: 120px;
      top: 75%;
    }
    .CircularProgressbar {
      width: 55px;
      text-align: center;
    }

    .CircularProgressbarDiv {
      position: relative;
      right: 50px;
      text-align: right;
      top: 50px;
    }

    .CircularProgressbarText {
      color: #fff;
      position: absolute;
      right: 20px;
      text-align: center;
      top: 8px;
      font-size: 16px;
    }

    .CircularProgressbarText p {
      margin: 0px;
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    .individual-graph-wrapper {
      display: inline-block;
      padding: 3%;
      text-align: center;
      width: 50%;
    }
    .graph-descp-comment,
    .graph-title {
      font-size: 11px;
      line-height: 14px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1379px) {
    .graph-title {
      font-size: 22px;
    }
    .graph-descp-comment {
      font-size: 20px;
    }
  }
}
