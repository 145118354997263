.cpp-data {
  @media screen and (min-width: 1000px) {
    width: 65%;
  }
}
.single-candidate-subtitle {
  border-bottom: 1.5px solid #abadb7;
  width: 200px;
  margin-bottom: 10px;
}
.custom-container {
  background-color: #f3f3f3;
  padding: 8px 16px 16px 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.add-question-wrapper {
  width: 40%;
}
.cpp-input-container {
  width: 100%;
  @media screen and (min-width: 1000px) {
    width: 48%;
  }
}

.cpp-input-container-h2 {
  width: 48%;
  @media screen and (min-width: 1000px) {
    width: 22%;
  }
}
.cpp-input-container-h3 {
  width: 48%;
  @media screen and (min-width: 1000px) {
    width: 20%;
  }
}
.cpp-input-container-h4 {
  line-height: 96px;
  height: 96px;
  text-align: center;
}

//editproduct//
.map-interviewer-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $theme-popup-bg;
  display: flex;
  justify-content: center;
  align-items: center;

  .map-interviewer-container {
    width: auto;
    height: auto;
    background-color: $Back-ground;
    border-radius: 0.5em;
    padding: 20px;

    .title {
      h4 {
        margin: 0;
      }
    }
    .map-interviewer-subheading {
      background-color: #e6e9f8;
      border-radius: 2px;
    }
    .map-interviewer-list {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
    .map-interviewer-list-wrapper {
      height: 200px;
      overflow: auto;
    }
    .map-interviewer-list-name {
      color: #133f7d;
    }
    .content-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        height: 500px;
        overflow: auto;
        .left,
        .right {
          margin: 10px 0;
        }
      }

      .left {
        width: 300px;
        height: 330px;
        overflow-y: auto;
        padding-right: 1em;

        .common-btn {
          padding: 10px 20px;
        }

        .view-icon {
          background-color: $theme-light-blue;
          text-decoration: none;
          color: $theme-blue;
          padding: 10px 20px;
          margin: 10px 0px;
          display: block;
        }
      }

      .right {
        width: 300px;
        height: 330px;
        overflow-y: auto;
        overflow-x: hidden;

        .qs-list {
          max-height: 80%;
          overflow-y: auto;
          margin: 10px 0;
          padding-right: 1em;

          label {
            margin: 5px 0;
          }

          .btn-close {
            width: 0.1em;
            height: 0.1em;
          }
        }

        .spc-qs-list {
          max-height: 48%;
          overflow-y: auto;
          margin: 10px 0;
          padding-right: 1em;

          label {
            margin: 5px 0;
          }

          .btn-close {
            width: 0.1em;
            height: 0.1em;
          }
        }
      }
    }
  }
}
.questions-wrapper {
  @media screen and (max-width: 900px) {
    width: 100%;
    position: relative;
    z-index: initial;
    top: 0;
    right: 0;
  }
  z-index: 3;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  height: calc(100% - 50px);
  padding: 30px;
  background-color: #f0f2f9;

  .screen-qs-container {
    height: 70%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $theme-blue;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $theme-blue;
    }
  }

  .btn-close {
    width: 5px;
    height: 5px;
    top: 2.5%;
    right: 1.5%;
  }

  .custom-qs {
    padding: 1.2em;
    outline: none;
    width: 95%;
  }

  .custom-qs-box {
    background-color: $White-Color;
    border-radius: 0.3em;
    overflow: hidden;
  }

  .screen-ques-remove {
    height: 2px;
    width: 2px;
  }
}
