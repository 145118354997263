.c-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $theme-blue;
  border: none;
  color: $White-Color;
  font-family: "Manrope-Regular";
  padding: 10px;
}
.red-btn {
  background-color: #f2464b;
  padding: 0.5em;
  text-align: center;
}
.c-outline-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid $theme-blue;
  color: $theme-blue;
  font-family: "Manrope-Regular";
  border-radius: 50px;
  padding: 1em;
  max-height: 50px;
}
.c-btn-small {
  max-height: 40px !important;
}

.common-btn,
.common-outline-btn {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
  padding: 0.7em 1em;
  border-radius: 0.375rem !important;
  cursor: pointer;
  img {
    margin-right: 5px;
  }
}

.common-btn {
  background-color: #133f7d;
  color: #ffffff;
  &:disabled {
    background-color: #e7e7e7;
    color: #626262;
    cursor: not-allowed;
  }
}

.common-outline-btn {
  background-color: transparent;
  color: #133f7d;
  border: 1.4px solid #133f7d;
  &:disabled {
    color: #626262;
    border: 1.4px solid #626262;
    cursor: not-allowed;
  }
}

.common-menu-btn {
  border-style: none;
  width: 30px;
  height: 30px;
  border-radius: 0.375rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e9f8;
  cursor: pointer;
  &:disabled {
    background-color: #e7e7e7;
    cursor: not-allowed;
  }
  img {
    height: 50%;
    width: auto;
  }
}
.c-height {
  height: 32px;
}
.evp-btns {
  white-space: nowrap;
}

.disable-common-btn {
  background-color: $theme-gray;
}

.signin-signup-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Manrope-Regular";
  padding: 1em;
  color: $White-Color;
}

.signin-blue {
  background-color: $theme-blue;
}

.signin-grey {
  background-color: $theme-subtitle-color;
}

.light-blue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $theme-light-blue;
  border-style: none;
}

.outline-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid $theme-blue;
  color: $theme-blue;
  background-color: transparent;
  border-radius: 0.4em;
  padding: 0.5em 1em;
}

.close-btn {
  border-style: none;
  background-color: transparent;
}
