.range-dropdown {
  margin: 10px 0;
  display: flex;
  align-items: center;
  .no-of-rows {
    width: auto;
    background-color: $White-Color;
    margin: 0 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px;
  }
}

.select-dropdown {
  background-color: $theme-light-blue;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
}
