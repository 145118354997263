// Color
$Back-ground: #f3f3f9;
$White-Color: #ffffff;
$Black-Color: #000000;
$theme-black: #0c0c0d;
$theme-light-gray: #c5c5c5;
$theme-blue: #1c4784;
$theme-gray: #cccccc;
$theme-border: #a9a9ab;
$theme-placeholder: #8780ab;
$theme-light-blue: #e7ebfb;
$theme-error: #ed184f;
$theme-light-red-bg: #b9140033;
$theme-mercury: #f6f7fb;
$theme-light-blue: #e6e9f8;
$theme-teal: #11b2b8;
$theme-light-chart: #e7ecff;
$theme-subtitle-color: #626262;
$theme-popup-bg: rgba(0, 0, 0, 0.5);
$theme-subscription-type: #4471b0;
$theme-lavender: #8780ab;
$theme-mercury: #f6f7fb;
$theme-light-blue: #e6e9f8;
$theme-peacock: #12b6bc;
$theme-red: #d80707;
$theme-green: #18652e;
$theme-yellow: #e8aa4e;
$theme-light-green: #23a566;
$theme-google: #ebebeb;
$theme-subuser: #626262;
$theme-charcoal: #2f2f44;
$theme-light-peacock: #e6f6f8;
$theme-fog: #ffffff4d;
$theme-light-red: #f2464b;
$theme-light-yellow: #fdf7ed;
$theme-green-background: #e8fbef;
$theme-red-background: #feedeb;
$theme-gold-background: #fff3cd;
$theme-carrot: #fe8471;
