.usage-dashboard-container {
  .udc-title-container {
    display: flex;
    justify-content: space-between;
    .udc-time-range-container {
      display: flex;
      align-items: center;
    }
  }
  .udc-table-divider {
    height: 1.2px;
    background-color: #abadb7;
  }
  .udc-table-header {
    display: flex;
    background-color: #e6e9f8;
    margin-top: 10px;
    margin-right: 20px;
    .udc-table-header-content {
      padding: 10px 5px;
      box-sizing: border-box;
      font-size: 12px;
      color: #626262;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      img {
        height: 8px;
        width: auto;
        cursor: pointer;
      }
    }
  }
  .udc-table-body {
    height: 250px;
    overflow-y: auto;
    padding-right: 10px;
    .udc-table-row {
      margin-top: 10px;
      display: flex;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      .udc-table-row-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 5px;
        text-align: center;
        font-size: 12px;
        .udc-table-row-status {
          padding: 5px 10px;
          height: auto;
          display: flex;
          align-items: center;
          .status-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        .status-type-1 {
          color: #0086d2;
          background-color: #edf7fc;
          .status-dot {
            background-color: #0086d2;
          }
        }
        .status-type-2 {
          color: #18ac00;
          background-color: #ebf5eb;
          .status-dot {
            background-color: #18ac00;
          }
        }
        .status-type-3 {
          color: #f2464b;
          background-color: #fef2f2;
          .status-dot {
            background-color: #f2464b;
          }
        }
        .status-type-4 {
          color: #626262;
          background-color: #f0f0f2;
          .status-dot {
            background-color: #626262;
          }
        }
        .status-type-5 {
          color: #ff822e;
          background-color: #fbf3ef;
          .status-dot {
            background-color: #ff822e;
          }
        }
        .status-type-6 {
          color: #e8aa4e;
          background-color: #faf5f1;
          .status-dot {
            background-color: #e8aa4e;
          }
        }
        .status-type-7 {
          color: #8b0000;
          background-color: rgba(139, 0, 0, 0.07);
          .status-dot {
            background-color: #8b0000;
          }
        }
      }
    }
  }
  .udc-subuser-table {
    margin-top: 15px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    .udc-subuser-title-container {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .udc-subuser-title {
        height: 100%;
        display: flex;
        align-items: center;
        width: 150px;
        border-bottom: 5px solid #133f7d;
        box-sizing: border-box;
      }
      .udc-subuser-drop-search-btn-container {
        display: flex;
        align-items: center;
        .search-input-type1 {
          border-style: none;
          width: 18em;
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: #c0c0c0;
          }
        }
      }
    }
  }
}

.udc-position-info-table {
  position: fixed;
  right: 0;
  bottom: 0;
  height: calc(100% - 50px);
  width: 480px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 10px;
  .udc-poisition-title-container {
    position: relative;
    text-align: center;
    color: #133f7d;
    font-size: 15px;
    .udc-position-info-close {
      position: absolute;
      right: 0;
      height: 5px;
      width: 5px;
    }
  }
  .udc-position-info-table-header {
    display: flex;
    font-size: 12px;
    color: #626262;
    margin-right: 5px;
    padding: 10px 0;
    .udc-position-info-table-header-content {
      width: 25%;
      text-align: center;
    }
  }
  .udc-position-info-table-row-container {
    height: calc(100% - 70px);
    overflow-y: auto;
    padding-right: 5px;
    .udc-position-info-table-row {
      display: flex;
      margin-top: 10px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      .udc-position-info-table-row-content {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 5px;
        font-size: 12px;
        .interview-id {
          background-color: #133f7d;
          color: #ffffff;
          padding: 5px;
        }
      }
    }
  }
}
