.tabs-container {
  margin: 0 20px;
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    width: 280px;
    font-size: 1em;
    color: $theme-blue;
    cursor: pointer;
    white-space: nowrap;
  }
  .tab-underline {
    width: 280px;
    height: 2px;
    bottom: 0px;
    background-color: $theme-blue;
  }
}
