.mpl-title-container {
  flex-wrap: wrap;
  button {
    @media screen and (max-width: 420px) {
      margin-top: 10px;
    }
  }
}

.white-bg {
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: $White-Color;
}

.mpl-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .main-product-card {
    margin: 2% 0 0 0;
    width: 48%;
    padding: 20px;
    border-radius: 0.5em;
    background-color: $White-Color;

    .title {
      img {
        height: 2em;
        max-width: 4em;
        object-fit: cover;
        margin-right: 20px;
      }

      color: $theme-blue;
    }

    .subtitle-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      color: $theme-subuser;
      .subtitle {
        width: 48%;
        margin: 1%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      img {
        height: 0.8em;
        margin-right: 5px;
      }
    }

    .psf {
      color: $theme-subuser;
      font-size: 0.5em;
      padding-left: 2em;
      margin: 8px 0;
    }

    .price-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-left: 1em;

      .price {
        line-height: 1;
        color: $theme-blue;
        span {
          font-size: 0.5em;
        }
      }
    }
  }
}

//editproduct//
.edit-product-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $theme-popup-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .edit-product-container {
    width: 900px;
    height: auto;
    background-color: $Back-ground;
    border-radius: 0.5em;
    padding: 20px 40px;
    .title {
      h4 {
        margin: 0;
      }
    }

    .content-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        // height: 500px;
        overflow: auto;
        .left,
        .right {
          margin: 10px 0;
        }
      }

      .left {
        width: 420px;
        height: 400px;
        overflow-y: auto;
        padding-right: 1em;
        .common-btn {
          padding: 10px 20px;
        }

        .view-icon {
          background-color: $theme-light-blue;
          text-decoration: none;
          color: $theme-blue;
          padding: 10px 20px;
          margin: 10px 0px;
          display: block;
        }
      }

      .right {
        width: 350px;
        height: 400px;
        overflow-y: auto;

        .qs-list {
          max-height: 80%;
          overflow-y: auto;
          margin: 10px 0;
          padding-right: 1em;

          label {
            margin: 5px 0;
          }

          .btn-close {
            width: 0.1em;
            height: 0.1em;
          }
        }

        .spc-qs-list {
          max-height: 48%;
          overflow-y: auto;
          margin: 10px 0;
          padding-right: 1em;

          label {
            margin: 5px 0;
          }

          .btn-close {
            width: 0.1em;
            height: 0.1em;
          }
        }
      }
    }
  }
}

.edit-panelist-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $theme-popup-bg;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-panelist-container {
  width: 600px;
  height: auto;
  background-color: $Back-ground;
  border-radius: 0.5em;
  padding: 40px;

  .title {
    h4 {
      margin: 0;
    }
  }

  .cancel-btn {
    background-color: #f2464b;
  }
  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @media screen and (max-width: 992px) {
      flex-direction: column;
      // height: 300px;
      overflow: auto;
      .left,
      .right {
        margin: 10px 0;
      }
    }

    .left {
      width: 350px;
      // height: 400px;
      overflow-y: auto;

      .common-btn {
        padding: 10px 20px;
      }

      .view-icon {
        background-color: $theme-light-blue;
        text-decoration: none;
        color: $theme-blue;
        padding: 10px 20px;
        margin: 10px 0px;
        display: block;
      }
    }

    .right {
      width: 350px;
      height: 400px;
      overflow-y: auto;

      .qs-list {
        max-height: 80%;
        overflow-y: auto;
        margin: 10px 0;
        padding-right: 1em;

        label {
          margin: 5px 0;
        }

        .btn-close {
          width: 0.1em;
          height: 0.1em;
        }
      }

      .spc-qs-list {
        max-height: 48%;
        overflow-y: auto;
        margin: 10px 0;
        padding-right: 1em;

        label {
          margin: 5px 0;
        }

        .btn-close {
          width: 0.1em;
          height: 0.1em;
        }
      }
    }
  }
}

//editproduct//

//sub-product-list//
.spl-link {
  margin: 20px 0;
  font-size: 12px;
  color: blue;
  cursor: pointer;
}
.spl-card-container.plans_spl-card-container {
  // display: grid;
  // gap:40px;
  // grid-template-columns: repeat(3,1fr);
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  gap: 20px;
}
.spl-card-container.plans_spl-card-container .type {
  height: 25px;
}
.spl-card-container.plans_spl-card-container .spl-main-card {
  height: 100%;
  position: relative;
  min-height: 360px;
}
.spl-card-container.plans_spl-card-container .spl-main-card .btn-container {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
.spl-card-container.plans_spl-card-container .spl-main-card .title {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}
.spl-card-container.plans_spl-card-container .spl-main-card .f-30 {
  font-size: 27px;
  margin-bottom: 0;
}
.spl-card-container.plans_spl-card-container .spl-main-card .details {
  margin: 15px 0;
  max-height: 200px;
  overflow: auto;
}
.mpl-title-container.white-bg {
  padding: 18px;
}
.add_edit_input_container .icon_change_container {
  margin-top: 26px;
}
.icon_change_container .common-btn {
  margin-bottom: 10px;
}
.spl-card-container {
  // display: flex;
  // flex-wrap: wrap;
  // align-items: flex-end;
  // display: grid;
  // gap:40px;
  // grid-template-columns: repeat(3,1fr);
  // @media screen and (max-width: 992px) {
  // justify-content: space-evenly;
  // }
  .spl-card {
    // margin: 4%;
    max-width: 240px;
    min-width: 238px;
    width: 100%;
  }

  .red {
    background-color: $theme-light-red;
  }

  .blue {
    background-color: $theme-blue;
  }

  .type {
    padding: 0.3em 2em;
    color: $White-Color;
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
    margin-right: 4px;
  }

  .spl-main-card {
    border: 3px solid $theme-light-blue;
    border-radius: 0.5em;
    padding: 1em 1.5em;
    height: auto;
    background: #ffffff;
    width: 100%;

    .title {
      font-size: 1.5em;
      font-weight: 500;
    }

    .price {
      span {
        color: $theme-subuser;
      }
    }

    .details {
      div {
        display: flex;
        align-items: flex-start;

        img {
          margin-right: 10px;
          display: block;
          margin-top: 0.2em;
        }
      }
    }

    .btn-container {
      margin-top: 1em;
    }
  }
}

//sub-product-list//

//attribute//
.ap-part-1,
.ap-part-2 {
  height: 50%;
}

.ap-part-1 {
  margin-top: 20px;
  .input-container {
    width: 35%;
    height: 100%;
    border-bottom: 1.5px solid $theme-subuser;
    border-right: 0.75px solid $theme-subuser;
    padding-right: 5%;

    .common-btn {
      padding: 1em 1.5em;
      display: block;
      margin: 3em auto 0 auto;
    }
  }

  .value-container {
    width: 65%;
    height: 100%;
    border-bottom: 1.5px solid $theme-subuser;
    border-left: 0.75px solid $theme-subuser;
    padding-left: 5%;

    .value-input-box {
      height: 85%;
      margin-top: 5px;
    }
  }
}

.ap-part-2 {
  .w-5 {
    width: 5%;
  }

  .w-15 {
    width: 15%;
  }

  .w-10 {
    width: 10%;
  }

  .w-30 {
    width: 30%;
  }

  .header {
    background-color: $theme-light-blue;
    width: 100%;
    color: $theme-subuser;

    tr {
      margin-bottom: 10px;
    }

    td {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      padding-left: 1em;
      line-height: 1;
      margin-bottom: 10px;
      text-align: center;

      img {
        width: 1em;
        margin-left: 5px;
        vertical-align: initial;
      }
    }
  }

  .row-container {
    height: 78%;
    overflow-y: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 10px;
      width: 100%;
    }

    tr {
      background-color: $White-Color;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }

    td {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      padding-left: 1em;
      line-height: 1;
      text-align: center;
    }

    .light-blue-btn {
      display: inline-block;
      height: 2.5em;
      width: 2.5em;
      border-radius: 0.3em;
      margin: 0 5px;

      img {
        width: 1em;
      }
    }
  }
}

//attribute//

//Custom-plan-list//
.custom-list-container {
  // height: 80%;
  overflow: auto;
  .w-5 {
    width: 5%;
  }

  .w-10 {
    width: 10%;
  }

  .w-15 {
    width: 15%;
  }

  .w-20 {
    width: 20%;
  }

  .custom-plan-header {
    background-color: $theme-light-blue;
    color: $theme-subuser;
    display: flex;
    padding: 22px 0;
    margin-bottom: 1em;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    @media screen and (max-width: 992px) {
      min-width: 980px;
    }

    .header-list {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 0.7em;
      }
    }
  }

  .custom-plan-list-box {
    height: 80%;
    overflow-y: auto;
    @media screen and (max-width: 992px) {
      min-width: 1000px;
    }

    .custom-plan-list {
      display: flex;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      margin-bottom: 10px;
      background-color: $White-Color;
      .list-content2 {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .light-blue-btn {
          width: 2.5em;
          height: 2.5em;

          img {
            height: 1em;
          }
        }
      }
      .list-content {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .light-blue-btn {
          width: 2.5em;
          height: 2.5em;

          img {
            height: 1em;
          }
        }
      }
    }
  }
}

//Custom-plan-list//

// Add edit products
.edit-product-page
  .edit-product-container
  .content-container
  .left.add_edit_left {
  width: auto;
  .custom-plan-popup-title {
    font-size: 14px;
    border-bottom: 0.3px solid #000;
    margin-bottom: 1%;
  }
  .permissions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    .permission-container {
      width: 24%;
      .permission-title {
        font-size: 12px;
        border-bottom: 0.3px solid #000;
        padding: 5px 0px;
      }
    }
  }
}
.add_edit_input_container {
  display: grid;
  gap: 0 10px;
  grid-template-columns: repeat(3, 1fr);
  .view-icon-container {
    width: 300px;
  }
  .common-input {
    min-width: 230px;
  }
  .file-input {
    border: none;
    .file {
      right: 0;
    }
  }
  .file_input_class {
    width: 100%;
    border: none;
    padding: 1.2em;
    font-family: "Manrope-Regular";
    outline: none !important;
    background-color: #ffffff;
  }
}
.add_edit_sw-card {
  display: flex;
  align-items: center;
  .permission-radio-btn {
    margin: 0 10px 0 20px;
    accent-color: #1c4784 !important;
  }
}
.add_edit_sw-card.sw-card .sw_cardName {
  order: 2;
}
.add_edit_qs-list {
  .blue-close-btn {
    height: 0.8rem;
    width: auto;
    cursor: pointer;
  }
}
.add_edit_qs-list.qs-list {
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 15px;
}
.add_edit_qs-list.qs-list label {
  min-width: 200px;
}
.add_edit_qs-list.qs-list .btn-close {
  width: 5px;
  height: 5px;
}
.add_highlight_input {
  min-width: 220px;
}
.add_highlight_btn {
  height: 46px;
}
.plan_save_btn {
  margin-bottom: -20px;
  margin-top: 15px;
}
.plan_card_title {
  font-size: 16px;
  line-height: 21.59px;
  color: #133f7d;
  font-weight: 700;
  text-transform: capitalize;
}
.price_btn_container .plan_switch_btn {
  background: #e6e9f8;
  color: #123a75;
  overflow: hidden;
  padding: 0.5em;
  text-align: center;
  width: 12em;
  font-size: 16px;
  border-radius: 50rem;
  border: none;
}
.price_btn_container .plan_switch_btn.yearly_plan_btn {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  margin-left: -30px;
  width: calc(12em - 30px);
}
.price_btn_container .plan_switch_btn.active {
  background: #fff;
}
.card-line {
  background-color: #e6e9f8;
  height: 1px;
}
.spl-card-container.plans_spl_card_container .spl-card {
  max-width: 280px;
  min-width: 280px;
}
.spl-card-container.plans_spl_card_container .spl-main-card {
  min-height: 400px;
}
.permission_logo_upload .file {
  right: 10px;
}
.save_permission_btn {
  max-width: 200px;
  width: 100%;
}
.border-none {
  border: none;
}
.credit_date .day {
  font-size: 24px;
  line-height: 32.98px;
  font-weight: 700;
  color: #f2464b;
}
.credit_date .month-year {
  color: #626262;
  font-size: 8px;
  font-weight: 400;
  line-height: 10.93px;
}
.filter_dropdown_btn::after {
  display: none;
}
.filter_dropdown_btn.show,
.filter_dropdown_btn:active,
.filter_dropdown_btn.focus-visible {
  border: none;
}
.filter_dropdown_menu {
  min-width: 300px;
}
.filter-clear-btn.incExit_dropdown_filter_btn {
  color: #133f7d;
}
.incExit_dropdown_label,
.c-label.incExit_dropdown_label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.39px;
  color: #989898 !important;
}
.sp-list .row-container .support-row .row-content .light-blue-btn.btn-primary {
  width: 90px;
  height: 25px;
  background-color: #133f7d;
  font-size: 9px;
  font-weight: 700;
  line-height: 10.93px;
  color: #fff;
  border-radius: 3px;
}
.sp-list .row-container .support-row .row-content .disable-btn.btn-primary {
  width: 90px;
  height: 25px;
  background-color: #737373;
  font-size: 9px;
  font-weight: 700;
  line-height: 10.93px;
  color: #fff;
  border-radius: 3px;
}
.edit-product-page .edit-product-container.incEXit_accept_popUp {
  max-width: 400px;
  width: 100%;
  padding: 24px;
}
.incEXit_accept_popUp .text_container {
  margin-top: 15px;
}
.incEXit_accept_popUp .text_container .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #101828;
  margin-bottom: 0;
  background: unset;
  -webkit-text-fill-color: unset;
}
.incEXit_accept_popUp .text_container .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #475467;
  margin-top: 5px;
}
.incEXit_accept_popUp .accept_icon {
  margin-top: -20px;
}
.incEXit_accept_popUp .btn_container {
  gap: 10px;
}
.incEXit_accept_popUp .accept_popUp_btn {
  height: 44px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}
.incEXit_accept_popUp .accept_popUp_btn.accept_btn {
  background-color: #039855;
  border-color: #039855;
  color: #fff;
}
.incEXit_accept_popUp .accept_popUp_btn.initiate_btn {
  background-color: #12b6bc;
  border-color: #12b6bc;
  color: #fff;
}
.call_btn {
  padding: 5px 10px;
  width: auto;
  background-color: #12b6bc;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 3px;
  margin: 0 5px;
  // img {
  //   margin-right: 5px;
  // }
}
.incExit_info_container .info_btn {
  max-width: 99px;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.39px;
  color: #ffffff;
  background-color: #12b6bc;
  border: none;
}
.incExit_info_container .info_btn.info_cancel_btn {
  border: 1px solid #d0d5dd;
  background-color: #ffffff;
  color: #344054;
}
.gap-20 {
  gap: 20px;
}
.bulk_back_btn.back-btn-container img {
  height: 0.99em;
  margin-top: -2px;
}
.bulk_back_btn.back-btn-container .back {
  font-size: 16px;
}
.bulk_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000000d9;
  margin: 17px 0 10px 0;
}
.bulk_table.table-list td,
.table-list th {
  padding: 11px 8px !important;
  font-size: 12px;
  font-weight: 600;
}
.status_box {
  display: flex;
  width: 100.876px;
  padding: 5px 10px;
  align-items: center;
  border-radius: 5px;
  background: #f2464b1a;
}
.status_box.pending {
  background: rgba(18, 182, 188, 0.1);
}
.status_box.accepted,
.status_box.approve {
  background-color: #00c8511a;
}
.status_box.accepted svg circle,
.status_box.approve svg circle {
  fill: #00c8511a;
}
.status_box.pending svg circle {
  fill: #12b6bc;
}
.status_box.approve .text,
.status_box.accepted .text {
  color: #00c851;
}
.status_box.pending .text {
  color: #12b6bc;
}
.status_box .text {
  color: #f2464b;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  margin: 0 auto;
}
.jd-resume-btn.bulk_csv_btn {
  max-width: 119.61px;
  width: 100%;
  height: 30px;
  font-size: 10px;
  font-weight: 500;
  border: none;
  position: relative;
}
.jd-resume-btn.bulk_csv_btn img {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.bulk_details_btn_container {
  display: flex;
  gap: 12px;
  align-items: center;
}
.accept_bulk.common-btn {
  background: #00c851;
  margin-top: 15px;
}
.incExit_edit_form_container {
  width: 65%;
}
.incExit_edit_form_container .row {
  --bs-gutter-y: 1.5rem;
}
.upload-type {
  color: #8780ab;
  line-height: 0;
}
.time_colon {
  font-size: 24px;
  font-weight: 500;
  line-height: 32.78px;
  color: #888888;
}
.schedule_popUp_title {
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  color: #0c0c0d;
}
.incExit_questions_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19.12px;
  color: #0044cc;
}
.questions_container td {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.questions_container .question_row {
  padding: 9px 6px 9px 6px;
  border-bottom: 2px solid #a8c1e4;
}
.w-90 {
  width: 90%;
}
.plus_btn {
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background-color: #133f7d;
  border: none;
  color: #fff;
  position: absolute;
  top: 36px;
  right: 0;
}
.gap-10 {
  gap: 10px;
}
.interviewer_btn {
  background-color: #133f7d;
}

tr:nth-child(even) {
  background-color: #f4f4f4;
}
td {
  tr:nth-child(even) {
    background-color: #ffffff;
  }
}
.filter-btn-popup.incExit .date-container,
.filter-btn-popup.incServe .date-container,
.filter-btn-popup.incBot .date-container {
  display: flex;
  gap: 20px;
}
.blue_link_text {
  cursor: pointer;
  color: #133f7d;
}
.interviewTimeIncExit .select__value-container.css-1fdsijx-ValueContainer {
  height: 48.13px;
  padding: 0 1.2em !important;
}
.reschedule_popUp_btn_container .reschedule_btn {
  width: 144px;
  height: 44px;
}
.accept_bulk.common-btn.close {
  background-color: #f2464b;
}
.pendingtext {
  color: #626262;
}
.acceptedtext {
  color: #18ac00;
}
.cancelledtext,
.deletedtext {
  color: #f2464b;
}
.text-muted {
  color: #626262 !important;
}
.addOrCancel.common-btn.replicate {
  width: 110px;
}
.whiteSpace-noWrap {
  white-space: nowrap;
}
.cp {
  cursor: pointer;
}
.overflow-auto {
  overflow: auto;
}
.sp-popup .popup-box.allot_interview_popUp_box.download {
  max-width: 460px;
  width: 100%;
}
