.change-password-container {
  .company-logo {
    height: 6em;
    @media screen and (min-width: 1620px) {
      height: 8em;
    }
  }
  .cpc-first-title {
    font-weight: 700;
    margin-bottom: 25px;
  }
  .cpc-secondary-container {
    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .cpc-container {
    width: 35%;
    margin-left: 2em;
    @media screen and (max-width: 992px) {
      width: 40%;
      margin-left: 1em;
    }
    @media screen and (max-width: 700px) {
      width: 70%;
      margin-left: 0;
      margin-top: 1em;
    }
    @media screen and (max-width: 580px) {
      width: 90%;
    }
  }
  .cpc-second-title {
    font-weight: 700;
    color: $theme-peacock;
    line-height: 1;
    @media screen and (min-width: 1620px) {
      font-size: 5em;
    }
    @media screen and (max-width: 1600px) {
      font-size: 4em;
    }
    @media screen and (max-width: 580px) {
      font-size: 3em;
    }
  }
  .eye-icon {
    margin: 0 1em;
  }
  .reset-page-btn {
    height: 2.5em;
    margin-top: 2em;
  }
}
