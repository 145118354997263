.form-select {
  color: $theme-black !important;
  border: 1px solid $theme-black;
  border-radius: 5px;
  //height: 40px;
  //padding-right: 35px;
  &:focus {
    border-color: $theme-black !important;
    outline: 0;
    box-shadow: none;
  }
  &::placeholder {
    font-size: 14px;
    color: #cccccc;
  }
}

.error {
  border: none !important;
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $theme-error;
  font-family: open-sans-bold, sans-serif;
}

select {
  position: relative;
  width: 100%;
  border: none;
  font-family: "Manrope-Regular";
  box-shadow: none !important;
  outline: none !important;
  background-color: $White-Color;

  &::placeholder {
    color: $theme-placeholder;
  }
}

.select__value-container {
  padding: 1.2em !important;
}
.select__input-container {
  margin: 0 !important;
  padding: 0 !important;
}
