.security-wrapper {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 15px;
  border-radius: 15px;
}
.security-qr-wrapper-container {
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
}
.security-qr-wrapper {
  background-color: #f4f4f4;
  width: 80%;
  padding: 20px 20px 0px;
}
.scanner-1 {
  width: 50%;
}
.scanner-2 {
  width: 50%;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}
// .title {
//   background: var(
//     --in-c-ruiter-new,
//     radial-gradient(100.11% 100.11% at 54.6% 0%, #12b6bc 0%, #126abc 100%)
//   );
//   background-clip: text;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// }
