.threedot-btn {
  border-style: none;
  background-color: $theme-light-blue;
  height: 2.4em;
  width: 2.4em;
  box-shadow: none !important;
  img {
    height: 1em;
  }
}
.threedot-menu {
  background-color: $White-Color !important;
  top: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 0px !important;
  border: none !important;
  width: 215px;
  .threedot-menu-content,
  .disabled-threedot-menu-content {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    color: $theme-subuser;
  }
  .threedot-menu-content {
    cursor: pointer;
    &:hover {
      background-color: $theme-light-blue;
      color: $theme-blue;
    }
  }
  .disabled-threedot-menu-content {
    cursor: not-allowed;
    background-color: #e7e7e7 !important;
    color: #626262 !important;
  }
  .text-theme-red {
    color: #f2464b !important;
  }
}

.fdb-new-btn {
  height: 30px;
  width: auto;
}
