.jd-form-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .jd-form-input {
    width: 45%;
  }
  .jd-form-radio-sw {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 25px;
    color: $theme-subuser;
    #jd-active,
    #jd-closed {
      height: 15px;
      width: 15px;
      margin-right: 5px;
      accent-color: $theme-blue;
      cursor: pointer;
    }
  }

  .react-quill {
    .ql-toolbar.ql-snow {
      border: none !important;
      background-color: #ffffff !important;
      border-radius: 0.375rem !important;
    }
    .ql-editor {
      background-color: #ffffff !important;
      border-radius: 0.375rem !important;
    }
    .ql-container.ql-snow {
      border: none !important;
    }
    .ql-toolbar.ql-snow {
      border: none !important;
    }
  }

  .jd-text-editor-container {
    width: 100%;
    .jd-text-btn {
      height: 40px;
      width: 40px;
      margin-right: 15px;
      overflow: hidden;
      border: 1.4px solid $theme-gray;
    }
    .jd-text-btn-container {
      margin: 25px 0;
    }
    .jd-text-editor {
      .ProseMirror {
        background-color: $White-Color !important;
        color: $theme-subuser;
        width: 100%;
        height: 300px;
        padding: 20px;
        overflow-y: scroll;
        border-radius: 5px;
        outline: none;
      }
    }
  }
}
