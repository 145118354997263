.eep {
  .eep-title-container {
    @media screen and (max-width: 598px) {
      flex-direction: column;
      align-items: start !important;
      .eep-title,
      .eep-search-exl {
        width: 100%;
        justify-content: space-between;
      }
      .eep-search-exl {
        flex-direction: row-reverse;
      }
    }
  }
  .eep-title {
    color: $theme-blue;
    font-size: 20px;
  }

  .eep-card-container {
    // margin-top: 10px;
    // padding-right: 8px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    @media screen and (max-width: 992px) {
      padding-right: 0px;
    }

    @media screen and (min-width: 1000px) {
      // height: 73%;
      // overflow-y: auto;
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $theme-blue;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: $theme-blue;
      }
    }
  }
}
