.employer-email-pending {
  overflow-y: auto;
  height: calc(100% - 50px);
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $theme-blue;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $theme-blue;
  }

  .clientInfo-back-container {
    padding: 0 20px;
    margin-top: 10px;
  }

  .tab-content-container {
    border-top: 2px solid $theme-border;
    display: flex;
    height: 100%;
    min-height: 500px;

    @media screen and (max-width: 991px) {
      display: block;
    }

    .tcc-side-panel {
      border-right: 1.5px solid $theme-border;
      padding: 1em;
      height: 100%;
      width: 28%;
      position: relative;
      .tcc-side-panel-tab {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
      .onboarded-div {
        position: absolute;
        bottom: 10px;
      }
      .side-panel-buttons-container {
        display: flex;
        justify-content: space-between;
        .side-panel-btn {
          border: none;
          padding: 10px;
          border-radius: 5px;
          font-size: 12px;
          width: 48%;
          color: #ffffff;
        }
        .side-panel-red-btn {
          background-color: #f2464b;
        }
        .side-panel-blue-btn {
          background-color: #133f7d;
        }
        .side-panel-green-btn {
          background-color: #00c851;
        }
      }
      .red-btn {
        width: 49%;
        background-color: red;
      }

      .main-content {
        width: 100%;
        padding: 0.5em;
        cursor: pointer;
        color: #626262;
        border-radius: 0.3em;
        font-size: 14px;
        .content-name {
          span {
            margin-left: 20px;
          }
          svg {
            height: 1em;
            width: 1em;
          }
        }

        .content-arrow {
          height: 1em;
        }

        .arrow-right {
          width: 0.6em;
        }
        .active-arrow {
          transform: rotate(-90deg);
        }
        .inactive-arrow {
          path {
            stroke: #626262;
          }
        }
      }

      .subcontent {
        padding: 0.5em;
        background-color: $White-Color;
        cursor: pointer;
        color: $theme-blue;
        font-size: 16px;

        &:hover {
          background-color: $theme-light-blue;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }

        svg {
          margin-right: 20px;
          height: 1em;
          width: 1em;
        }
      }

      .blue {
        background-color: hsl(0, 0%, 100%);
        color: $theme-blue;
      }

      .profile-btn {
        padding: 0.9em 0;
        font-size: 12px;
        text-align: center;
        color: white;
        border-radius: 0.5em;
        margin-bottom: 8px;
        cursor: pointer;
      }
      .verify-btn {
        background-color: #00c851 !important;
        color: #ffffff;
      }
      .profile-red-btn {
        background-color: #f2464b;
      }
      .profile-teal-btn {
        background-color: #12b6bc;
      }
    }

    .tcc-main-panel {
      border-left: 1.5px solid $theme-border;
      height: 100%;
      width: 72%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      @media screen and (max-width: 991px) {
        width: 100%;
      }

      .tcc-main-title {
        color: $theme-blue;
        background-color: #ffffff;
        // height: 10%;
        padding: 1em;
      }

      .tcc-main-content {
        display: flex;
        flex-direction: column;

        .tcc-input-container {
          width: 40%;
          margin: 0.2em 0;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        .tcc-btn-container {
          width: 40%;
          margin-top: 2em;

          .mail-veri-btn {
            background-color: $theme-blue;
            color: $White-Color;
            padding: 1em 1.5em;
          }
        }
      }
    }
  }
  .profile-img-container {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background-color: #848181;
    overflow: hidden;
  }
  .profile-img {
    height: 100%;
    width: auto;
  }
}

// .userinfo-userdetail {
.userinfo-profile-container1 {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  // border: 2px solid #133f7d;
  display: flex;
  align-items: center;
  justify-content: center;
  .user-image-container {
    height: 96px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    .user-profile-img {
      height: 100px;
      width: auto;
    }
  }
  .company-logo-container {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .edit-profile-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: $theme-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .input-edit-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
    .edit-icon {
      height: 10px;
      width: auto;
      position: absolute;
      top: 7px;
      right: 6px;
    }
  }
  .userinfo-userdetail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
// margin-top: 40px;

.userinfo-profile-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;

  .company-logo-container {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .user-profile-img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      border-radius: 50%;
    }
  }

  .edit-profile-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: $theme-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.company-logo-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  overflow: hidden;

  .user-profile-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    object-fit: cover;
    object-position: top center;
  }

  .edit-profile-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: $theme-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .input-edit-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
      color: transparent;
      ::placeholder {
        color: transparent;
      }
    }
    img {
      height: 50%;
      width: auto;
    }
  }
}
