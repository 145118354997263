.sp-list {
  overflow: auto;
  .header {
    background-color: $theme-light-blue;
    color: $theme-subuser;
    margin-bottom: 1em;
    min-height: 55px;
    min-width: 1200px;
    @media screen and (max-width: 992px) {
      min-width: 980px;
    }
    .header-content {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px 0;
      img {
        width: 1em;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  .row-container {
    // height: calc(100% - 75px);
    // overflow: auto;
    min-width: 1200px;
    // padding-right: 20px;
    .support-row {
      background-color: $White-Color;
      color: $theme-subuser;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      margin-bottom: 1em;
      padding: 10px 0;
      min-height: 55px;
      .row-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        .light-blue-btn {
          width: 25px;
          height: 25px;
          border-radius: 0.25em;
          img {
            width: 1em;
            height: 1em;
          }
        }
        .disable-btn {
          border-radius: 0.25em;
          background-color: red;
          border-style: none;
        }
        // .status-div {
        //   align-items: center;
        //   display: flex;
        //   justify-content: center;
        //   padding: 5px;
        // }
        .status {
          height: 2.2em;
          width: 8em;
          border-radius: 0.4em;
        }
        .support-opened {
          color: #ff9f43;
          border: 1px solid #ff9f43;
        }
        .support-resolved {
          color: #18ac00;
          border: 1px solid #18ac00;
        }
        .support-closed {
          color: #d80707;
          border: 1px solid #d80707;
        }
        .hyperlink {
          color: #133f7d;
          text-decoration: underline;
        }
        .activePosition {
          color: $theme-light-green;
        }
        .deletedPosition {
          color: $theme-error;
        }
      }
      .feedback-btn {
        border: none;
        // height: 3em; affecting other css
        padding: 5px 15px;
        background-color: #133f7d;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.jd-resume-btn {
  padding: 3px 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #133f7d;
}

.sp-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  z-index: 9;

  .faq-popUp-width {
    width: 400px;
  }

  .popup-box {
    height: auto;
    width: 660px;
    background-color: $Back-ground;
    border-radius: 1em;
    padding: 2em;

    .title {
      line-height: 1;
      h3 {
        margin: 0;
      }
      .close-btn {
        img {
          height: 1em;
        }
      }
    }
    .input-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 400px;
      overflow-y: auto;
      padding-right: 10px;
      .input-field {
        margin: 1% 0;
      }
      .textarea {
        width: 100%;
      }
      .btn-container {
        margin-top: 2em;
      }
    }
  }
}

.faq-tag {
  padding: 5px 10px;
  color: #133f7d;
  background-color: #c6cfe0;
}
.sp-popup .popup-box.allot_interview_popUp_box {
  max-width: 560px;
  width: 100%;
}
