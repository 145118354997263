.edit-skill-set-page {
  width: 100%;
  height: 100%;
  padding: 30px;
  .addOrCancel {
    border: none;
    padding: 8px 0;
    width: 80px;
  }
  .cancel-btn {
    border: 1.4px solid $theme-blue;
    background-color: transparent;
    color: $theme-blue;
  }
  .add-btn {
    background-color: $theme-blue;
    color: $White-Color;
    border: 1.4px solid $theme-blue;
  }

  .screen-qs-list {
    height: 48%;
    overflow-y: auto;
    padding-right: 1em;
    label {
      margin: 5px 0;
    }
    .btn-close {
      width: 0.1em;
      height: 0.1em;
    }
  }
  .add-qs {
    border: none;
    padding: 8px 20px;
  }
}

//screening-questions-css//
.screening-questions-wrapper {
  z-index: 3;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 33%;
  height: calc(100% - 50px);
  background-color: #f0f2f9;
  .w-90 {
    width: 90%;
  }
  .screen-qs-wrapper {
    padding: 30px 30px 10px;
    height: calc(100% - 100px);
  }

  .screen-qs-container {
    height: calc(100% - 50px);
    overflow-y: auto;
  }

  .screen-qs-container2 {
    height: calc(100% - 80px);
    // background: #e6e9f8;
    overflow-y: auto;
  }

  .btn-close {
    width: 5px;
    height: 5px;
    top: 2.5%;
    right: 1.5%;
  }

  .custom-qs {
    padding: 1.2em;
    outline: none;
    width: 95%;
  }

  .custom-qs-box {
    background-color: $White-Color;
    border-radius: 0.3em;
    overflow: hidden;
  }
  .custom-qs-container {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
  }
  .sq-generate-btn {
    color: white;
    background-color: #133f7d;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .sq-generate-btn1 {
    color: white;
    background-color: #9b9b9b;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .qs-question-input {
    width: 100%;
    border: none;
    padding: 1em;
    font-family: "Manrope-Regular";
    outline: none !important;
    background-color: #ffffff;
  }
  .qs-add-div {
    display: flex;
    align-items: center;
    background-color: #133f7d;
    color: white;
    margin: 0px 10px;
    padding: 0px 16px;
    border-radius: 3px;
  }
  .qs-remove-icon {
    height: 13px;
    width: 13px;
    cursor: pointer;
  }
}
//screening-questions-css//

.info-container {
  position: fixed;
  transition: all 0.5s ease-out;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
  right: 0;
  bottom: 0;
  height: calc(100% - 50px);
  width: 680px;
  overflow-y: auto;
  background-color: #ffffff;
  .btn-close {
    height: 5px;
    width: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .list {
    height: 100%;
    padding: 25px;
    .position-table-container {
      height: 100%;
      overflow-y: auto;
      .table-list {
        .tl-header {
          td {
            color: $theme-subuser;
          }
        }
        .tl-row {
          .list-id,
          .view-more {
            padding: 5px;
            background-color: $theme-blue;
            border-radius: 0.4em;
            color: $White-Color;
            img {
              height: 0.8em;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .my-position-feedback {
    background-color: $White-Color;
    color: $theme-placeholder;
    .back-close {
      padding-left: 10px;
      .btn-close {
        height: 0.4em;
        width: 0.4em;
      }
    }
    .status-box {
      .status {
        color: $White-Color;
        padding: 0.3em 1em;
        border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
      }
    }
    .fb-btn {
      padding: 0.6em 1em;
      border-radius: 0.5em;
      img {
        height: 1em;
        margin: 0 5px;
      }
    }
    .candi-name,
    .list-title {
      color: $Black-Color;
    }
    .divider {
      height: 1px;
      background-color: $theme-border;
    }
  }
}

/* feedback report css */
.download-pdf {
  width: 1em;
}

.candi-feedback-box {
  height: 100px;
}

.candi-performance {
  height: 90%;
  width: 100px;
  svg {
    height: 100%;
  }
}

.man-skill-box {
  height: auto;
  border: 1.5px solid $theme-light-blue;
}

.performance-review {
  width: 70%;
}

.skill-performance {
  height: 70px;
  width: 70px;
  margin-top: 4px;
  svg {
    height: 100%;
  }
}

.dot {
  height: 0.3em;
  width: 0.3em;
  border-radius: 50%;
}

.dot-review-red {
  color: $theme-red;
}

.dot-review-green {
  color: $theme-green !important;
}

.dot-review-yellow {
  color: $theme-yellow !important;
}

.skill-review-box,
.final-remarks {
  height: auto;
  width: 100%;
  padding: 10px;
  border: 1px solid $theme-placeholder;
  color: $theme-placeholder;
  text-align: justify;
}

.info-btn-close {
  width: 5px;
  height: 5px;
  top: 10px;
  right: 10px;
}

.red {
  background-color: $theme-red !important;
}

.green {
  background-color: $theme-green !important;
}

.yellow {
  background-color: $theme-yellow !important;
}

.carrot {
  background-color: $theme-carrot !important;
}

.grey {
  background-color: $theme-subuser !important;
}

.id-sticker,
.status-sticker {
  display: inline-block;
  color: $White-Color;
  padding: 0.2em 0;
  text-align: center;
  width: 8em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.status-sticker {
  margin-left: 5px;
}

.id-sticker {
  background-color: $theme-blue;
}

.meeting-link-btn {
  border-style: none;
  background-color: $theme-light-blue;
  font-size: 10px;
  margin-right: 10px;
  padding: 5px;
  color: $theme-blue;
  height: 2.4em;
  width: 2.4em;
  .copy-link {
    width: 10px;
    height: auto;
  }
}

.card-btn-list {
  right: 0;
  position: absolute;
  margin-top: 30px;
  z-index: 99;
}

.info-main-container {
  position: fixed;
  background-color: #fff;
  height: calc(100% - 60px);
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.5);
  border: 2px solid red;
  right: 0;
  padding: 0 12px 12px;
  transition: all 0.5s ease-out;
  z-index: 10000;
  top: 60px;
  @media screen and (min-width: 993px) {
    width: 50%;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.invite-div-join-meet {
  background-color: $theme-light-blue;
  border: none;
  margin-right: 10px;
  height: 2.4em;
  width: 2.4em;
  text-decoration: none;
  cursor: pointer;
  .join-meet-img {
    height: 40%;
    width: auto;
  }
}

.interview-card a:hover {
  color: #ffffff;
}
.calender-invite-content .w-47 {
  min-width: 90px;
}

.feedback-download-btn {
  border: none;
  background-color: $theme-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $White-Color;
  .feedback-download-arrow {
    height: 13px;
    width: auto;
  }
}

.very-poor-bg {
  background-color: #ff0000;
}
.poor-bg {
  background-color: #ff9f43;
}
.average-bg {
  background-color: #e8aa4e;
}
.good-bg {
  background-color: #8db627;
}
.very-good-bg {
  background-color: #30a400;
}
.excellent-bg {
  background-color: #008c15;
}

.very-poor-color {
  color: #ff0000;
}
.poor-color {
  color: #ff9f43;
}
.average-color {
  color: #e8aa4e;
}
.good-color {
  color: #8db627;
}
.very-good-color {
  color: #30a400;
}
.excellent-color {
  color: #008c15;
}

.final-rating-text {
  text-align: center;
  width: 100px;
}

.status-pending {
  background-color: #f0f0f2 !important;
}
.status-rejected {
  background-color: #e96f68 !important;
}
.status-hold {
  background-color: #e8aa4e !important;
}
.status-scheduled {
  background-color: #12b6bc !important;
}
.status-shortlisted {
  background-color: #61be52 !important;
}
