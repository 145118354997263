.calender-invite {
  height: 500px;
  width: 600px;
  background-color: $White-Color;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.4em;
  background-color: #f6f7fb;

  .calender-invite-mail-body {
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .mail-link {
      background-color: white;
      word-wrap: break-word;
      padding: 10px;
      color: #565656;
      border: none;
      background-color: lightgray;
      resize: none;
    }
    .mail-body {
      background-color: white;
      word-wrap: break-word;
      padding: 10px;
      color: #565656;
      border: none;
      resize: none;
      overflow: auto;
      &:focus {
        outline: none;
      }
    }
  }

  .label {
    color: $theme-placeholder;
  }

  .candidate,
  .interviewer {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      cursor: pointer;
    }
  }

  .ci-title-box {
    color: $theme-blue;
    width: 100%;
    .ci-close-btn {
      width: 0.3em;
      height: 0.3em;
    }
  }
  .calender-invite-content {
    height: 90%;
    overflow-y: auto;
    width: 100%;
    padding: 0 20px;
    .gray-border {
      border: 1px solid #cccccc;
    }
  }
  .ci-input-box {
    .ci-role-input {
      height: 2.5em;
      width: 90%;
      padding-left: 0.5em;
      border-style: none;
      border-bottom: 1px solid $theme-placeholder;
      color: $theme-blue;
    }

    .ci-datetime-input {
      height: 2.5em;
      width: 90%;
      border-style: none;
      padding-left: 0.5em;
      border: 1px solid $theme-placeholder;
      color: $theme-blue;
    }

    .ci-mail-input {
      height: 15em;
      width: 90%;
      border-style: none;
      padding: 0.5em;
      border: 1px solid $theme-placeholder;
      color: $theme-blue;
    }

    .ci-to-input,
    .ci-guest-input {
      height: 2.5em;
      width: 90%;
      padding-left: 0.5em;
      border-style: none;
      border: 1px solid $theme-placeholder;
      color: $theme-blue;
    }
  }

  .ci-invite-btn {
    padding: 0.6em 1.2em;
  }
  .ReactTags__tagInputField {
    width: 100%;
    border: none;
    padding: 1.2em;
    &:focus {
      outline: none;
    }
  }
}
