.interview-tabs-box {
  .interview-tab {
    padding: 8px 15px;
    background-color: $theme-subuser;
    border-left: 1px solid #f0f0f0;
    border-top: 1px solid #f1f1f1;
    border-right: 1px solid #f5f5f5;
    margin: 0 0.5px;
    border-top-left-radius: 0.4em;
    border-top-right-radius: 0.4em;
    cursor: pointer;
    color: $White-Color;
  }
  .selected {
    background-color: $theme-blue;
  }
}

.status-shorlisted {
  background-color: #61be52;
}
.status-scheduled {
  background-color: #5793b5;
}
.status-hold {
  background-color: #e8aa4e;
}
.status-noshow {
  background-color: #626262;
}
.status-rejected {
  background-color: #e96f68;
}
.status-cancelled {
  background-color: #884d49;
}
.status-pending {
  background-color: #9b9b9b;
}

.status-shorlisted {
  color: #61be52;
}
.status-scheduled {
  color: #5793b5;
}
.status-hold {
  color: #e8aa4e;
}
.status-noshow {
  color: #626262;
}
.status-rejected {
  color: #e96f68;
}
.status-cancelled {
  color: #884d49;
}
.status-pending {
  color: #9b9b9b;
}

.status-color {
  color: #ffffff;
  width: 130px;
}
.allot_popUp_btn_container.delete-box-buttons .allot_btn{
  max-width: 100px;
  height: 36px;
  width: 100%;
} 
