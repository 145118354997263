.sub-history-container {
  .shc-title-container {
    color: $theme-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .add-sub-btn {
      background-color: $theme-blue;
      color: $White-Color;
      padding: 0.4em 0.8em;

      img {
        margin-right: 5px;
      }
    }
  }

  .sub-table-container {
    margin-top: 1%;
    // height: 88%;
    overflow-y: auto;

    .w-10 {
      width: 10%;
    }
    .w-15 {
      width: 15%;
    }
    .w-20 {
      width: 20%;
    }

    .sub-table-header {
      display: flex;
      padding: 10px;
      background-color: $theme-light-blue;
      color: $theme-subuser;
      margin: 0 1% 10px 1%;
      .header-content {
        padding-left: 5px;
      }
      img {
        width: 0.8em;
        margin-left: 5px;
      }
    }
    .sub-table-content {
      height: 80%;
      padding: 0 1%;
      overflow-y: auto;
      margin-bottom: 10px;

      .sub-table-list {
        display: flex;
        padding: 10px;
        background-color: $White-Color;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 8px;
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 5px;

          .ongoing {
            background-color: $theme-green-background;
            color: $theme-light-green;
          }

          .canceled {
            background-color: $theme-light-yellow;
            color: $theme-yellow;
          }

          .expired {
            background-color: $theme-red-background;
            color: $theme-red;
          }

          .prepaid {
            background-color: $theme-light-blue;
            color: $theme-blue;
          }
          .postpaid {
            background-color: $theme-gold-background;
            color: $theme-yellow;
          }

          .status,
          .type {
            height: 2.4em;
            width: 8em;
            border-radius: 0.4em;
          }

          .permission-btn {
            height: 2.4em;
            width: 2.4em;
            border-style: none;
            border-radius: 0.4em;
            img {
              height: 1em;
            }
          }
        }
      }
    }
  }
}

//edit subcription page

.edit-subscription-container {
  .esc-title-container {
    display: flex;
    align-items: center;
    color: $theme-subuser;

    .esc-title {
      margin-left: 20px;
    }
  }

  .esc-main-container {
    margin: 20px 0 0 0;
    .esc-input-container {
      width: 40%;

      .api-integration {
        background-color: $White-Color;
        border-radius: 0.4em;
      }
    }
  }

  .credits-history {
    color: $theme-blue;
  }

  .credits-history-table {
    background-color: $White-Color;
    margin: 20px 0;
    min-height: 30vh;
    // height: 84%;
    .credit-search-container {
      padding: 2%;
      .credit-search {
        img {
          height: 1.2em;
          margin: 0 10px;
        }
        .search-bar {
          padding: 0.5em;
          width: 20em;
          border-style: none;
          outline: 0;
          &::placeholder {
            color: $theme-border;
          }
        }
      }
    }

    .add-credits {
      background-color: $theme-blue;
      color: $White-Color;
      padding: 0.5em 1em;

      img {
        margin-right: 5px;
      }
    }

    .credit-table-header {
      border-bottom: 2px solid $theme-light-gray;
      margin: 0 2%;
      span {
        padding: 1em 0;
        text-align: center;
      }
    }
    .credit-table-row-container {
      padding: 0 2%;
      height: 64%;
      overflow-y: auto;
    }
    .credit-table-row {
      border-bottom: 2px solid $theme-light-gray;
      span {
        padding: 1em 0;
        text-align: center;
      }
      .action-btn {
        height: 2em;
        width: 2em;
        img {
          height: 1em;
        }
      }
    }
  }

  .add-btn {
    background-color: $theme-blue;
    padding: 0.5em 1em;
    color: $White-Color;
    margin-bottom: 1em;
  }

  .popup-container {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .popup-box {
      padding: 20px 40px;
      width: 30%;
      background-color: $White-Color;

      .close-btn {
        img {
          height: 1em;
        }
      }

      .popup-main-box {
        .popup-input-container {
          width: 100%;
        }
      }
      .popup-btn-container {
        margin-top: 20px;
        .red {
          background-color: $theme-light-red;
        }
        .blue {
          background-color: $theme-blue;
        }
        .common-btn {
          padding: 0.5em 1em;
          margin: 0 20px;
          color: $White-Color;
        }
      }
    }
  }
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

// add-sub-popup
.sub-history-container {
  .add-sub-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);

    .popup-box {
      padding: 25px;
      background-color: $Back-ground;
      width: 350px;
      border-radius: 0.5em;
      position: relative;
      .btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
        height: 15px;
        width: 15px;
        img {
          height: 1em;
        }
      }
      .popup-title {
        text-align: center;
      }

      .popup-main {
        margin: 0 auto;
        .popup-btn-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          .popup-btn {
            height: 3em;
            width: 48%;
            color: $White-Color;
          }
          .red {
            background-color: #f2464b !important;
          }

          .blue {
            background-color: #1c4784 !important;
          }
        }
      }
    }
  }
}
