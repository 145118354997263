.credit-card {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 12px 10px;
  width: 32% !important;
  margin-bottom: 20px;
  height: 68px;
  display: flex;
  justify-content: space-between;
}
.credit-title {
  color: #454256;
  width: 70%;
  word-break: auto-phrase;
}
.text-theme-teal {
  color: #12b6bc;
}
.text-theme-red {
  color: #f2464b;
}
.divider {
  border-bottom: 2px solid rgba(199, 199, 199, 0.3);
}
.credit-details-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
  .credit-details {
    width: 100%;
    min-width: 900px;
    .credit-details-row {
      height: 60px;
    }
  }
  .credit-details-search-container {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 20px 0 20px 20px;
    border-bottom: 1px solid #cdcdcd;
    .search-img {
      height: 18px;
      width: auto;
      margin-right: 20px;
    }
    .credit-details-search-bar {
      border: none;
      outline: none;
      &::placeholder {
        color: #a7afc0;
      }
    }
  }
}
.product-permissions-box {
  .permissions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    .permission-container {
      width: 32%;
      .permission-title {
        font-size: 12px;
        border-bottom: 0.3px solid #000;
        padding: 5px 0px;
      }
    }
  }
  .product-permissions-box-title {
    font-size: 14px;
    border-bottom: 0.3px solid #000;
    padding-bottom: 5px;
  }
}
.credit-details {
  background-color: #ffffff;
  border-radius: 10px;
  width: 100% !important;
  tr {
    border-bottom: 1px solid #cdcdcd;
  }
  th {
    white-space: nowrap;
  }
  th,
  td {
    padding: 10px;
    text-align: center;
    font-size: 12px;
  }
}
.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-7 {
  width: 7%;
}
.w-20 {
  width: 20%;
}
.manage-subscription-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  .manage-subscription-checkbox {
    display: flex;
    align-items: center;
    margin: 5px 20px 5px 0px;
  }
}
.permission_input_container {
  .file-input .real-file-input {
    padding: 1.1em 2.5em 1.1em 1.2em;
  }
}
