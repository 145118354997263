.all-main-tab-wrapper {
  display: flex;
  color: #191919;
}
.all-interview-para {
  color: #133f7d;
}
.all-main-tab {
  background-color: #e9e9e9;
  padding: 9px 16px;
}
.all-sub-tab {
  background-color: #e9e9e9;
  padding: 7px 14px;
}
.all-control-wrapper {
  display: flex;
  justify-content: space-between;
}
.all-right-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.all-download-btn {
  border: none;
  width: 148px;
  border: 1px solid #133f7d;
  border-radius: 5px;
  padding: 0 12px;
  height: 35px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #133f7d;
}
.align-flex-end {
  justify-content: flex-end;
}
.all-active {
  background-color: #e6e9f8;
  color: #133f7d;
}

.bulk-interview-band {
  background-color: #ffffff;
  padding: 10px;
  color: #133f7d;
}
.btn {
  background-color: #133f7d;
  color: #ffffff;
  padding: 2px 12px;
  border-radius: 5px;
}
.accpet-btn {
  background-color: #00c851;
  border-radius: 5px;
  color: #ffffff;
  padding: 2px 12px;
  border: none !important;
}
