.cic-profile-pic {
  font-size: 22px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: $White-Color;
  margin-left: 15px;
  overflow: hidden;
  .cic-img {
    height: 100%;
    width: auto;
  }
}

.cic-person {
  color: $theme-placeholder;
}
