.interviewer-schedule-model {
  position: fixed;
  bottom: 12px;
  right: 20px;
  margin-right: 0.5rem;
  //  background-color: rgba(0, 0, 0, 0.5);
  // background-color: red;
}
.calender-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-color: #133f7d;
  background-color: #133f7d;
  z-index: 2;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.calender-popup {
  background-color: #fff;
  margin: 10px 0;
  width: 500px;
  height: auto;
  // position: absolute;
  // bottom:100%;
  // right: 100%;
  border-radius: 10px;
  // box-sizing: border-box;
  // background-color: #F6F7FB;
  // color:#000000;
}
.container {
  height: 200px;
  overflow-y: scroll;
}
