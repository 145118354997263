.forgot-popup-main {
  left: 0;
  top: 0;
  background-color: $theme-popup-bg;
  .forgot-box-content {
    height: 85%;
    @media screen and (max-width: 580px) {
      flex-direction: column;
    }
    .forgot-content {
      .forgot-content-title {
        color: $theme-peacock;
        font-weight: bold;
        line-height: 1;
        margin-top: 50px;
        margin-bottom: 10px;
        @media screen and (min-width: 300px) {
          font-size: 40px;
        }

        @media screen and (min-width: 500px) {
          font-size: 42px;
        }

        @media screen and (min-width: 700px) {
          font-size: 56px;
        }

        @media screen and (min-width: 1000px) {
          font-size: 58px;
        }
        @media screen and (min-width: 1200px) {
          font-size: 60px;
        }

        @media screen and (min-width: 1400px) {
          font-size: 65px;
        }

        @media screen and (min-width: 1600px) {
          font-size: 70px;
        }

        @media screen and (min-width: 1800px) {
          font-size: 90px;
        }
      }
      .forgot-content-btn {
        height: 2.8em;
        width: 12em;
        @media screen and (max-width: 992px) {
          margin: 0 auto;
        }
      }
    }
  }
  .forgot-popup-box {
    height: 75%;
    width: 50%;
    top: 12.5%;
    left: 40%;
    background-color: $Back-ground;
    border-radius: 0.5em;
    @media screen and (max-width: 992px) {
      height: 50%;
      width: 80%;
      left: 10%;
      top: 25%;
    }

    @media screen and (max-width: 580px) {
      height: 70%;
      width: 80%;
      left: 10%;
      top: 10%;
    }

    .btn-close {
      height: 6px;
      width: 6px;
    }
    .forgot-popup-img {
      width: 40%;
      @media screen and (max-width: 580px) {
        width: 60%;
      }
    }
    .forgot-content {
      width: 60%;
      margin-left: 1em;
      @media screen and (max-width: 580px) {
        width: 90%;
        margin-left: 0;
      }
    }
    .sign-in-container {
      width: 100%;
      bottom: 20px;
      right: 10px;
      text-align: end;
      @media screen and (max-width: 580px) {
        text-align: center;
      }
    }
  }
}

.sign-in-option {
  color: $theme-blue;
  font-weight: 700;
  cursor: pointer;
}

.forgot-input {
  width: 75%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
