// .file-input {
//   border: 0.1px dashed $theme-blue;
//   input {
//     width: 100%;
//     background-color: transparent;
//     padding: 1.2em;
//   }
//   img {
//     z-index: 0;
//   }
// }
// .upload-type {
//   color: $theme-placeholder;
// }

.file-input {
  border: 0.1px dashed $theme-blue;
  .real-file-input {
    width: 100%;
    background-color: transparent;
    padding: 1.2em 2.5em 1.2em 1.2em;
  }
  .real-file-input2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: white;
    padding: 1.2em 2.5em 1.2em 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .real-file-text-gray {
    color: #918ab2;
  }
  .file {
    position: absolute;
    z-index: 3;
    height: 80%;
    width: auto;
    right: 0;
    bottom: 12%;
  }
  .close-mark {
    top: 37.5%;
    right: 5%;
    height: 25%;
    cursor: pointer;
    z-index: 1;
  }
}
