@media print {
  body {
    -webkit-print-color-adjust: exact; /* For Chrome and Safari */
    color-adjust: exact; /* For Firefox */
    margin: 0;
    padding: 0;
  }
}
@page {
  size: A4;
  margin: 0;
  padding: 0;
}

// Reports css start
.reports_container {
  padding: 40px;
  width: 100%;
  background-color: #eff3f8;
  .report-print-btn {
    border: none;
    background-color: $theme-peacock;
    padding: 10px 20px;
    color: $White-Color;
  }
  .report-inner-container {
    width: 800px;
    margin-top: 40px;
    background-color: white;
  }
  .report-table {
    padding-top: 60px;
    background-color: white;
  }
  .reports_logo {
    width: 100%;
    height: 70px;
  }
  .dashboard-chart-container {
    background-color: white;
  }
  .header-title {
    margin-top: 20px;
    background-color: #133f7d;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin-bottom: 20px;
  }
  .header-title h2 {
    text-decoration: underline;
    margin-bottom: 15px;
  }

  .header-title h3 {
    margin-bottom: 4px;
  }
  .break-word {
    word-break: break-word;
  }
  .data_info_container {
    border: 2px solid rgba(244, 244, 244, 1);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    width: 23%;
  }

  .data_info_container .text {
    color: rgba(18, 182, 188, 1);
    margin-bottom: 10px;
  }

  .data_info_container .num {
    color: #133f7d;
    font-size: 18px;
  }

  .data_info_container .num span {
    color: rgba(244, 148, 84, 1);
    font-size: 15px;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table_head {
  border-bottom: 1px solid;
}

th {
  font-weight: 700;
  font-size: 18px;
}

th,
td {
  padding: 18px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

@media print {
  .reports_container {
    background-color: #abadb7;
    padding: 30px !important;
    .report-print-btn {
      display: none;
    }
    .header-title {
      margin-top: 20px !important;
      background-color: #133f7d !important;
      padding: 20px !important;
      display: flex;
      flex-direction: column !important;
      align-items: center;
      color: #fff !important;
      margin-bottom: 20px !important;
    }
  }
  .data_info_container {
    width: 300px !important;
  }
}
