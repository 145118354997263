.switch-field {
  display: flex;
  overflow: hidden;
  width: 75%;
  margin: 25px auto;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: $White-Color;
  width: 50%;
  line-height: 1;
  text-align: center;
  padding: 1em 0;
  margin-right: -1px;
  color: $theme-blue;
  transition: all 0.3s;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: $theme-blue;
  box-shadow: none;
  color: $White-Color;
}
