.settle-btn {
  padding: 5px 0;
  width: 100px;
  border: none;
  border-radius: 0.5em;
}
.settle-btn-2 {
  padding: 5px 0;
  width: 200px;
  border: none;
  border-radius: 0.5em;
}
.settle-active {
  color: $White-Color;
  background-color: $theme-blue;
}
.input-bg {
  background-color: rgba(98, 98, 98, 0.4);
  color: black;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
}

.settle-inactive {
  color: #a7a7a7;
  background-color: #ececed;
}

.settled {
  background-color: #c9f1db;
  color: #00c851;
}
.pending {
  background-color: rgba(98, 98, 98, 0.2);
  color: #626262;
}

.client-status {
  padding: 5px;
  background-color: $theme-light-blue;
  color: $theme-blue;
  border-radius: 0.5em;
}

.settle-reward-popup {
  position: absolute;
  background-color: $Back-ground;
  padding: 20px 40px;
  border-radius: 10px;
  .voucher-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.2em;
    text-align: center;
    width: 88px;
    height: 40px;
    background-color: white;
    border-radius: 5px;
  }
  .voucher-input {
    cursor: pointer;
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 88px;
    height: 40px;
    border-radius: 5px;
  }
  .voucher-span {
    position: absolute;
    font-size: 12px;
  }
  .voucher-input:checked {
    background-color: #133f7d;
  }
  .voucher-input:checked + .voucher-span {
    color: $White-Color;
  }
  .settle-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 10px;
    width: 10px;
  }
}

.settle-btn-t1 {
  color: #fff;
  background: #133f7d;
}
.settle-btn-t2 {
  color: #626262;
  background: rgba(98, 98, 98, 0.2);
}
