// Color
$theme-black: #0c0c0d;
$White-Color: #ffffff;
$theme-placeholder: #8780ab;
$theme-red: #ff0000;
$theme-peacock: #12b6bc;
$theme-blue: #133f7d;

.c-label {
  font-family: "Manrope-SemiBold";
  color: $theme-black !important;
}

.eye-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.common-date-input {
  .date-input-img {
    position: absolute;
    width: 24px;
    height: auto;
    right: 5px;
    top: calc(50% - 12px);
  }
}

.text-area {
  resize: none;
  padding: 15px;
}

.select__control {
  border-style: none !important;
}

.select__control--is-focused {
  border-style: none !important;
  border-color: white !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}

.asterisk {
  color: $theme-red;
}

.wrapper {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
  display: flex;

  .loader {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 5px solid $theme-blue;
    border-top-color: #ffffff;
    box-sizing: border-box;
    background: transparent;
    animation: loading 1s linear infinite;
    margin: auto;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(360deg);
    }
  }
}
