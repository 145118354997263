.product-list-container {
  .plc-title {
    color: $theme-blue;
  }

  .pl-card-container {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .pl-card {
      position: relative;
      padding: 15px;
      background-color: $White-Color;
      height: auto;
      width: 330px;
      margin: 20px 0;
      .redirect-img {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        width: 15px;
        height: auto;
      }
      .title {
        color: $theme-blue;
        margin-bottom: 5px;

        .pic {
          margin-right: 10px;

          .pic-img {
            width: 50px;
            height: auto;
          }
        }
      }

      .subtitle-container {
        color: $theme-placeholder;
        display: flex;
        flex-wrap: wrap;

        .subtitle {
          width: 50%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 5px 0;

          img {
            height: 1em;
            margin: 0 5px;
          }
        }
      }

      .pl-card-btn {
        padding: 0.4em 0.8em;
      }

      .view-details-btn {
        background-color: #133f7d;
        color: #ffffff;
        border: 1.4px solid #133f7d;
      }

      .contact-us-btn {
        background-color: #ffffff;
        color: #133f7d;
        border: 1.4px solid #133f7d;
        cursor: not-allowed;
      }

      .blue {
        background-color: $theme-blue;
        color: $White-Color;
      }

      .white {
        background-color: $White-Color;
        color: $theme-blue;
      }
    }
  }
}

.plans-container {
  .pc-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #133f7d;
    background-color: #ffffff;
    height: 10%;
    padding: 1em;

    .pc-title {
      color: $theme-blue;
      margin-left: 20px;
    }

    .create-plan-btn {
      background-color: $theme-blue;
      color: $White-Color;
      padding: 0.5em 1em;

      img {
        height: 0.8em;
        margin-right: 5px;
      }
    }
  }

  .pc-card-container {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;

    .pc-card {
      width: 240px;
      margin: 20px;

      .sticker-container {
        .plan-type,
        .plan-status {
          padding: 2.5px 8px;
          border-top-right-radius: 0.5em;
          border-top-left-radius: 0.5em;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        }

        .plan-type {
          color: $White-Color;
        }

        .blue {
          background-color: $theme-blue;
        }

        .red {
          background-color: $theme-light-red;
        }

        .plan-status {
          .status-dot {
            height: 0.8em;
            width: 0.8em;
            border-radius: 50%;
            margin-right: 5px;
          }

          .active {
            background-color: $theme-peacock;
          }

          .inactive {
            background-color: $theme-placeholder;
          }
        }
      }

      .recommend-radius {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .normal-radius {
        border-radius: 5px;
      }

      .main-card {
        padding: 1em;
        border: 2px solid $theme-light-blue;
        position: relative;

        .recommend-label {
          position: absolute;
          bottom: 100%;
          left: 0%;
          width: 100%;
          background-color: #133f7d;
          color: #ffffff;
          text-align: center;
          padding: 8px 0;
        }

        .price-container {
          color: $theme-blue;
          display: flex;
          align-items: center;
          justify-content: center;

          .price {
            font-size: 50px;
          }
        }

        .plan-divider-line {
          height: 1px;
          background-color: #dddddd;
          width: 50%;
          margin: 8px auto;
        }

        .plan-details-container {
          margin-top: 5px;
          height: 200px;
          overflow-y: auto;

          .plan-detail {
            display: flex;
            align-items: flex-start;
          }
        }

        .edit-delete-container {
          margin-top: 15px;

          .ed-btn {
            border-style: none;
            background-color: $theme-light-blue;
            height: 1.5em;
            width: 1.5em;
            margin: 0 3px;

            img {
              height: 0.8em;
            }
          }
        }

        .square-tick {
          margin-right: 10px;
        }
      }
    }
  }
}

//podruct pop box

// .plans-container {
//   .product-pop-up {
//     position: fixed;
//     top: 0%;
//     left: 0%;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.5);
//     z-index: 5;

//     .pop-up-box {
//       padding: 25px 50px;
//       width: 60%;
//       height: 90%;
//       background-color: $Back-ground;

//       .title-box {
//         height: 10%;
//         h3 {
//           margin: 0;
//         }
//         .close-btn {
//           img {
//             height: 1em;
//           }
//         }
//       }

//       .popup-parts {
//         height: 90%;
//         .popup-part1,
//         .popup-part2 {
//           width: 48%;
//           height: 100%;
//           overflow-y: auto;
//         }

//         .popup-part1 {
//           .input-container {
//             width: 90%;

//             .common-btn {
//               padding: 1em 1.5em;
//             }
//           }
//         }
//         .popup-part2 {
//           .qs-list {
//             height: 50%;
//             overflow-y: auto;
//             .close-btn {
//               img {
//                 height: 0.5em;
//               }
//             }
//           }
//         }
//       }

// .edit-box {
//   height: 90%;
//   .edit-box1 {
//     width: 50%;
//     padding: 0 20px;
//     overflow-y: auto;

//     .edit-box1-checkbox {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin: 10px 0;
//       input {
//         margin-right: 10px;
//       }
//     }

//     .switch-container {
//       display: flex;
//       align-items: center;
//       justify-content: space-around;

//       .switch-box {
//         background-color: $White-Color;
//         padding: 0.5em;
//         display: flex;
//         align-items: center;
//         flex-direction: column;

//         .switch-box-name {
//           margin: 5px 0;
//         }
//       }
//     }

//     .save-btn {
//       padding: 1em 2em;
//       margin: 20px auto;
//       background-color: $theme-blue;
//       color: $White-Color;
//     }
//   }
//   .edit-box2 {
//     width: 45%;

//     .feature-container {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       margin: 5px 0;

//       .feature {
//         display: flex;
//         align-items: center;

//         input {
//           margin-right: 10px;
//         }
//       }

//       .close-feature {
//         height: 0.2em;
//         width: 0.2em;
//       }
//     }

//     .add-feature {
//       color: $theme-blue;
//       margin: 10px 0;
//       cursor: pointer;
//     }

//     .add-feature-input-container {
//       width: 45%;
//     }

//     .add-btn {
//       background-color: $theme-blue;
//       color: $White-Color;
//       padding: 1em 2em;
//       margin: 20px auto;
//     }
//   }
// }
//     }
//   }
// }
