.cp-main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
  .tcc-input-container {
    width: 45%;
    margin: 0.2em 0;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .tcc-text-container {
    width: 100%;
    margin: 0.2em 0;
  }
  .tcc-btn-container {
    width: 100%;
    margin: 1em 0;
    .mail-veri-btn {
      background-color: $theme-blue;
      color: $White-Color;
      padding: 8px 20px;
    }
  }
}
.interviewer-link-btn {
  display: block;
  border: 1.4px solid $theme-blue;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  color: $theme-blue;
  cursor: pointer;
}
.interviewer-link-disable-btn {
  display: block;
  border: 1.4px solid #8780ab;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  color: #8780ab;
  cursor: pointer;
}
.interviewer-rating-box-container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  .interviewer-rating-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px;
  }
}
